import PropTypes from 'prop-types';
import React from 'react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ReferenceDot,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';

import useStore from '../../state/KioskStore';
import { addUnixTime, getTimesBetween } from './../../lib/DateUtil';
import CustomTick from './CustomTick';
import GraphLabel from './GraphLabel';
import TimeTick from './TimeTick';

export const allNull = (data, key) => {
  if (data.length === 0) {
    return true;
  }
  return data.every((item) => item[key] === null);
};

const Graph = ({
  timeSeriesData,
  name,
  dataKey,
  lineColor,
  areaColor,
  positiveLabel = null,
  negativeLabel = null,
  downIcon = null,
  upIcon = null,
  height = null,
  width = null,
}) => {
  const getArea = () => {
    return (
      <Area
        type={'monotone'}
        dataKey={dataKey}
        stroke={lineColor}
        strokeWidth={3}
        connectNulls={true}
        dot={false}
        key={dataKey}
        fill={areaColor}
        fillOpacity={1}
        duration={0.5}
      />
    );
  };

  const store = useStore((state) => state);

  const timeseriesUnixTimestamp = addUnixTime(timeSeriesData);
  const getMinTimestamp = () => timeseriesUnixTimestamp[0].unixTimestamp;
  const getMaxTimestamp = () => timeseriesUnixTimestamp.slice(-1)[0].unixTimestamp;

  const getTimeTicks = () => {
    let times = [];
    if (store.displayTimeRangeKey === 'lastDay') {
      times = getTimesBetween(new Date(getMinTimestamp()), new Date(getMaxTimestamp()), 'hour', 4);
    } else {
      times = getTimesBetween(new Date(getMinTimestamp()), new Date(getMaxTimestamp()), 'day', 1);
    }

    const timesUnitTimestamp = times.map((time) => time.getTime());

    return timesUnitTimestamp;
  };

  const hasNonNull = !allNull(timeSeriesData, dataKey);

  return (
    timeseriesUnixTimestamp.length &&
    hasNonNull && (
      <ResponsiveContainer>
        <AreaChart
          data={timeseriesUnixTimestamp}
          margin={{ top: 50, right: 0, left: 0, bottom: 60 }}
          {...(width ? { width, height } : {})}
          type="monotone"
        >
          <CartesianGrid strokeDasharray="6 3" syncWithTicks={true} stroke="#E8E8E8" />
          {getArea()}
          <YAxis tickCount={5} tick={<CustomTick />} axisLine={false} tickLine={false} width={70} />
          <YAxis />
          <XAxis
            domain={['dataMin', 'dataMax']}
            type="number"
            axisLine={false}
            tickLine={false}
            dataKey="unixTimestamp"
            interval={0}
            tick={<TimeTick />}
            height={0.2}
            ticks={getTimeTicks()}
          />
          <XAxis />
          {positiveLabel && negativeLabel && (
            <ReferenceDot
              x={getMinTimestamp()}
              y={0}
              r={5}
              fill="transparent"
              stroke="none"
              label={
                <GraphLabel
                  upIcon={upIcon}
                  downIcon={downIcon}
                  positiveLabel={positiveLabel}
                  negativeLabel={negativeLabel}
                  lineColor={lineColor}
                />
              }
              ifOverflow="extendDomain"
            />
          )}
        </AreaChart>
      </ResponsiveContainer>
    )
  );
};

Graph.propTypes = {
  timeSeriesData: PropTypes.array,
  name: PropTypes.string,
  dataKey: PropTypes.string,
  lineColor: PropTypes.string,
  areaColor: PropTypes.string,
  positiveLabel: PropTypes.string,
  negativeLabel: PropTypes.string,
  downIcon: PropTypes.string,
  upIcon: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

export default Graph;
