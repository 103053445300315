import PropTypes from 'prop-types';
import React from 'react';
import { XAxis } from 'recharts';

import GraphUtil from './../../lib/GraphUtil';

const CustomXAxis = (props) => {
  const displayRange = props.displayRange;
  delete props.displayRange;
  return (
    <XAxis
      dataKey="bucket"
      tick={{ stroke: '#bebebe', strokeWidth: 0.1 }}
      axisLine={false}
      domain={['dataMin', 'dataMax']}
      tickFormatter={GraphUtil.getTickFormatter(displayRange)}
      interval={100}
      style={{
        fontFamily: 'Inter, Arial, Helvetica, sans-serif',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '8px',
        height: '50px',
      }}
      xAxisId={0}
      {...props}
    >
      {props.children}
    </XAxis>
  );
};

CustomXAxis.propTypes = {
  hide: PropTypes.bool,
  displayRange: PropTypes.object,
  children: PropTypes.object,
};

export default CustomXAxis;
