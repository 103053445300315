import useMediaQuery from '@mui/material/useMediaQuery';

import smsTheme from '../style/theme';

const mobileBreakpointKey = 'sm';

const useMobile = () => {
  return !useMediaQuery(smsTheme.breakpoints.up(mobileBreakpointKey));
};

const useMobilePassThrough = () => {
  return useMobile() && smsTheme.classes.passThroughMobile;
};

const useHideMobile = () => {
  return useMobile() && smsTheme.classes.hideMobile;
};

export { useHideMobile, useMobile, useMobilePassThrough };
