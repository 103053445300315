import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import { formatDateString, keys } from '../Logic/FormatUtils';

/**
 * CustomTooltip component renders the custom tooltip content for the Recharts AreaChart.
 *
 * @param {boolean} active - Indicates if the tooltip is active.
 * @param {array} payload - Array of tooltip payload objects.
 * @param {string} label - The label of the tooltip.
 * @returns {JSX.Element|null}
 */
const CustomTooltip = ({ active, payload, label, getName, getUnit }) => {
  if (active && payload && payload?.length) {
    const sortedPayload = payload.sort((a, b) => {
      return keys.indexOf(a.name) - keys.indexOf(b.name);
    });

    return (
      <Box display="flex" flexDirection="column">
        <div className="tooltip-custom">
          <div className="tooltip-container">
            <p className="tooltip-label">{`${formatDateString(payload[0].payload.bucket)}`}</p>
            {sortedPayload.map((entry, index) => {
              const unit = getUnit ? getUnit(entry.name) : 'kW';
              const name = getName
                ? getName(entry.name)
                : entry.name.charAt(0).toUpperCase() + entry.name.replace(/_/g, ' ').slice(1);
              return (
                <div className="tooltip-item" key={index}>
                  <span className={`tooltip-color`} style={{ backgroundColor: entry.color }}></span>
                  <span className="tooltip-type">{`${name}`}: </span>
                  <span className="tooltip-value">
                    {`${entry.value !== null && entry.value !== undefined ? ` ${parseFloat(entry.value.toFixed(1))} ${unit}` : ' --'}`}
                  </span>
                  <br />
                </div>
              );
            })}
          </div>
        </div>
      </Box>
    );
  }

  return null;
};

CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.number,
      payload: PropTypes.object,
    }),
  ),
  label: PropTypes.string,
  getBucketAlarms: PropTypes.func,
  timezone: PropTypes.string,
  getUnit: PropTypes.func,
  getName: PropTypes.func,
};

export default CustomTooltip;
