import { useAuth0 } from '@auth0/auth0-react';
import PropTypes from 'prop-types';
import { useEffect } from 'react';

const Login = ({ setAuthData }) => {
  const { loginWithRedirect } = useAuth0();

  const prevPath = window.location.pathname;
  const prevSearch = window.location.search;
  const redirectURL = prevPath + prevSearch;

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: redirectURL,
      },
    });
  };

  useEffect(() => {
    handleLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginWithRedirect]);

  return null;
};

Login.propTypes = {
  setAuthData: PropTypes.func,
};

export default Login;
