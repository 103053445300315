import { Box, Button } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const styles = {
  centerContainer: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  warningContainer: {
    width: { xs: '100%', md: '24rem' },
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  warningBox: {
    pl: { lg: '15px', xl: '20px' },
    pr: { lg: '30px', xl: '40px' },
    py: { xs: '15px', lg: '9px', xl: '12px' },
    border: '1.75px solid #FEBF21',
    borderLeft: '6px solid #FEBF21',
    borderRadius: '8px',
    backgroundColor: '#FEF7EA',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: { lg: '16px', xl: '24px' },
    mb: '27px',
    width: { xs: '100%', md: 'auto' },
  },
  warningBodyTextContainer: {
    fontSize: { lg: '15px', xl: '20px' },
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    pb: { lg: '15px', xl: '20px' },
  },
  warningRetryButton: {
    fontSize: { lg: '15px', xl: '20px' },
    color: '#57BCDC',
    textDecoration: 'underline',
  },
  imageBox: {
    width: { lg: '25px', xl: '38px' },
    mr: { xs: '1.5rem', xl: '1.5rem' },
  },
};

const ErrorCallout = ({ showRetry = false }) => (
  <Box sx={styles.centerContainer}>
    <Box sx={styles.warningContainer}>
      <Box sx={styles.warningBox}>
        <Box sx={styles.imageBox}>
          <img src="/icons/warning-icon.svg" alt="warning icon" />
        </Box>
        Error loading the data
      </Box>
      <Box sx={styles.warningBodyTextContainer}>
        <p style={{ textAlign: 'center', margin: 0 }}>
          Something went wrong and we were unable to load the data. Please reach out to your Scale
          associate.
        </p>
      </Box>

      {showRetry && (
        <Button variant="text" sx={styles.warningRetryButton}>
          Retry
        </Button>
      )}
    </Box>
  </Box>
);

ErrorCallout.propTypes = {
  showRetry: PropTypes.bool,
};

export default ErrorCallout;
