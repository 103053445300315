import dayjs from 'dayjs';
var utc = require('dayjs/plugin/utc');
var timezone = require('dayjs/plugin/timezone'); // dependent on utc plugin

dayjs.extend(utc);
dayjs.extend(timezone);

const Util = {
  convertTz: (date, tzString) => {
    const inTz = new Date(
      (typeof date === 'string' ? new Date(date) : date).toLocaleString('en-US', {
        timeZone: tzString,
      }),
    );
    return inTz;
  },
  toISOStringWithTimezone: (date, tzString) => {
    return dayjs(date).tz(tzString, true).format();
  },
  decorateAlarms: (alarms, assemblies) => {
    alarms.forEach((alarm) => {
      let assembly = assemblies.find((assembly) => assembly.uuid === alarm.assembly_uuid);
      if (assembly) {
        alarm.source = assembly.name;
      }
    });
  },
};

export default Util;
