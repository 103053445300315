import PropTypes from 'prop-types';
import React from 'react';

const formatTick = (value) => {
  let formattedTick = `${value}kW`;
  return formattedTick;
};

const CustomTick = ({ x, y, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={4} dx={-5} fill={'#BFBFBF'} fontSize={14} textAnchor="end">
        {formatTick(payload.value)}
      </text>
    </g>
  );
};

CustomTick.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  payload: PropTypes.object,
};

export default CustomTick;
