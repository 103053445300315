import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';

import { findSeries } from '../Logic/FormatUtils';
import { getSiteTimeSeries, getTimeSeriesMetrics } from './../../../../lib/Queries';
import Util from './../../lib/Util';

const DownloadDialog = ({
  start,
  stop,
  defaultInterval,
  open,
  onClose,
  params,
  metricsUuids,
  site,
}) => {
  const [interval, setInterval] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const handleClose = () => {
    setInterval(-1);
    setData(null);
    onClose();
  };

  const CSVDownload = (props) => {
    const btnRef = useRef(null);
    useEffect(() => {
      btnRef.current?.click();
      handleClose();
    }, [btnRef]);
    return (
      <CSVLink {...props}>
        <span ref={btnRef} />
      </CSVLink>
    );
  };

  const fetchData = () => {
    setLoading(true);
    let loadInterval = interval === -1 ? defaultInterval : interval;

    // Conditional execution based on the presence of 'metrics'
    if (metricsUuids) {
      // Log the return value of the function
      const queryResult = getTimeSeriesMetrics(
        params.siteUuid,
        start,
        stop,
        loadInterval,
        metricsUuids,
      );
      queryResult
        .then((res) => {
          setLoading(false);
          const Series = res.time_series;
          const csvData = getCsvData(Series, metricsUuids, site);
          setData(csvData);
        })
        .catch((err) => {
          console.error('Error in queryResult:', err);
          setLoading(false);
        });
    } else {
      getSiteTimeSeries(params.siteUuid, start, stop, loadInterval)
        .then((res) => {
          setLoading(false);
          setData(res.time_series);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  /**
   * Converts the time series data from UUIDs to human readable fields
   */
  const getCsvData = (timeSeries, metrics, site) => {
    const csvData = [];
    if (metrics.length === 0 || timeSeries.length === 0) return csvData;

    const header = ['bucket'];
    // create the header row first
    const firstBucket = timeSeries[0];
    let keys = Object.keys(firstBucket);
    keys.forEach((key) => {
      const result = findSeries(key, site);
      if (result && result.tsMetric) {
        header.push(
          result.assemblyName + ' ' + result.tsMetric.name + ' (' + result.tsMetric.unit + ')',
        );
      }
    });
    csvData.push(header);
    timeSeries.forEach((bucket) => {
      const row = [];
      row.push(Util.toISOStringWithTimezone(bucket.bucket, site.timezone));
      keys = Object.keys(bucket);
      keys.forEach((key) => {
        const result = findSeries(key, site);
        if (result && result.tsMetric) {
          row.push(bucket[key]);
        }
      });
      csvData.push(row);
    });
    return csvData;
  };

  return (
    <Dialog open={open}>
      <DialogTitle>
        <Typography variant="h4" color="secondary">
          Download Data
        </Typography>
      </DialogTitle>
      <Box p={3}>
        <Box>
          <Box pb={3}>
            <Typography variant="body2">
              Data Range: {new Date(start).toLocaleString()} to {new Date(stop).toLocaleString()}
            </Typography>
          </Box>
          <FormControl fullWidth>
            <InputLabel id="interval-select-id">Data Interval</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={interval}
              label="Data Interval"
              onChange={(ev) => setInterval(ev.target.value)}
            >
              <MenuItem value={-1}>As shown on graph</MenuItem>
              <MenuItem value={900}>15 minutes</MenuItem>
              <MenuItem value={3600}>Hourly</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box pt="2em" display="flex" flexDirection="row" width="100%" justifyContent="end">
          <Button
            onClick={handleClose}
            variant="outline"
            sx={{ marginLeft: '1em', width: '150px' }}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={loading}
            onClick={fetchData}
            variant="contained"
            sx={{ marginLeft: '1em', width: '150px' }}
          >
            Download
          </LoadingButton>
        </Box>
      </Box>
      {data ? (
        <CSVDownload
          data={data}
          target="_blank"
          filename={'scaleos-export-' + new Date().toISOString() + '.csv'}
        />
      ) : null}
    </Dialog>
  );
};

DownloadDialog.propTypes = {
  start: PropTypes.string,
  stop: PropTypes.string,
  defaultInterval: PropTypes.number,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  params: PropTypes.object,
  metricsUuids: PropTypes.arrayOf(PropTypes.string),
  site: PropTypes.object,
};
export default DownloadDialog;
