import { timeStringDifferenceHours } from './DateUtil';

const sum = (arr) => arr.reduce((a, b) => a + b, 0);

const sumField = (arr, field) => sum(arr.map((obj) => obj[field]));

const isNumber = (val) => typeof val === 'number';

export const validateBESSPercentage = (val) => {
  if (val == null) return null;
  return val < 100 && val >= 0 ? val : null;
};

const getBessSoc = (sitePowerData) => {
  const assembly = sitePowerData.assemblies.find((assembly) => assembly.source === 'BESS');
  const valRaw = assembly.soc;
  const val = validateBESSPercentage(valRaw);
  return { val, hasLiveData: val !== null };
};

export const getEnergyKwh = (timeSeriesData, field) => {
  const validPoints = timeSeriesData.map((obj) => obj[field]).filter(isNumber);

  if (timeSeriesData.length === 0 || validPoints.length === 0) {
    return null;
  }

  const powerSum = sum(validPoints);
  const startTime = timeSeriesData[0].bucket;
  const stopTime = timeSeriesData.slice(-1)[0].bucket;
  const durationHours = timeStringDifferenceHours(startTime, stopTime);
  const interval = durationHours / (timeSeriesData.length - 1);
  const energyKwh = powerSum * interval;

  return energyKwh;
};

const getGensetCapacity = (assembly) => {
  const val = assembly.percentage;
  return { val, hasLiveData: val !== null };
};

const addAllGensetCapacity = (flatAssets, metricsData) => {
  const gensetAssets = flatAssets.filter((asset) => asset.source.includes('Genset'));
  gensetAssets.forEach((asset) => {
    const gensetMetric = getGensetCapacity(asset);
    const gensetKey = asset.source + '_Capacity';
    metricsData[gensetKey] = gensetMetric;
  });

  return metricsData;
};

const getSolarEnergyKwh = (assembly, todayTimeSeriesData) => {
  const solarEnergyKwh = getEnergyKwh(todayTimeSeriesData, assembly.source);
  const solarMetric = {
    val: solarEnergyKwh,
    hasLiveData: solarEnergyKwh !== null,
    name: 'solarEnergyKwh',
  };
  return solarMetric;
};

const addAllSolarEnergyKwh = (flatAssets, metricsData, todayTimeSeriesData) => {
  const solarAssets = flatAssets.filter((asset) => asset.source.includes('Solar'));
  solarAssets.forEach((asset) => {
    const solarMetric = getSolarEnergyKwh(asset, todayTimeSeriesData);
    const solarKey = asset.source + '_EnergyKwh';
    metricsData[solarKey] = solarMetric;
  });

  return metricsData;
};

export const substitute_aggregate_fields = (resourceFields, desiredResourceTypes) => {
  let fields = [...resourceFields];
  for (const resourceType of desiredResourceTypes) {
    const hasResource = fields.some((resource) => resource.includes(resourceType));
    if (!hasResource) {
      fields.push(resourceType);
    }
  }

  return fields;
};

export const getEnergyAllKwh = async (timeSeriesData) => {
  if (!timeSeriesData || timeSeriesData.length === 0) {
    return null;
  }

  const fieldBaseNames = ['Solar', 'Genset'];
  const columnNames = Object.keys(timeSeriesData[0]);
  const excludeStrings = ['min', 'max'];
  let generationFieldNames = columnNames.filter(
    (col) =>
      fieldBaseNames.some((base) => col.includes(base)) &&
      !excludeStrings.some((excludedValue) => col.includes(excludedValue)) &&
      col.match(/\d+/g), // only include numbered generation assets
  );

  const availableFieldNameBases = fieldBaseNames.filter((base) =>
    columnNames.some((name) => name.includes(base)),
  );

  generationFieldNames = substitute_aggregate_fields(generationFieldNames, availableFieldNameBases);

  const generationAssetTotals = generationFieldNames.map((name) => ({
    name: name,
    energyKwh: getEnergyKwh(timeSeriesData, name) ?? 0, //set null to zero
  }));

  const grandTotalKwh = sumField(generationAssetTotals, 'energyKwh');

  return { assets: generationAssetTotals, totalKwh: grandTotalKwh };
};

const getResourceRoot = (resource) => {
  return resource.split('_')[0];
};

export const formatResourceKey = (key) => {
  const parts = key.split('_');
  const root = parts[0];
  const index = parts.length === 2 ? parts[1] : '';

  let formattedRoot = root.charAt(0).toUpperCase() + root.slice(1);

  if (root.toLowerCase() === 'gensetCapacity') {
    formattedRoot = 'Generator Capacity';
  }

  const formattedKey = `${formattedRoot} ${index}`.trim();

  return formattedKey;
};

// determines if we should use numbered resources or aggrigate.  If numbered, return the root, ex  "Solar".
export const getNumberedResourceRoot = (resources) => {
  const resourceRoots = resources.map(getResourceRoot);
  const resourceRootSet = new Set(resourceRoots);
  resourceRootSet.delete('Load');
  const hasNumberedResources = resources.some((resource) => resource.match(/_\d+$/));
  return resourceRootSet.size === 1 && hasNumberedResources ? resourceRoots[0] : null;
};

const getNonNumberedResources = (resources) => {
  return resources.filter((resource) => !resource.match(/_\d+$/));
};

const getNumberedResourcesByRoot = (resources, root) => {
  const selectedRootResources = resources.filter((resource) => getResourceRoot(resource) === root);
  const numberedResources = selectedRootResources.filter((resource) => resource.match(/_\d+$/));
  const numericSortedResources = numberedResources.sort();
  return numericSortedResources;
};

// returns aggrigate or numbered resource list
export const getSelectedResources = (resources) => {
  const numberedResourceRoot = getNumberedResourceRoot(resources);

  if (numberedResourceRoot) {
    return getNumberedResourcesByRoot(resources, numberedResourceRoot);
  }

  return getNonNumberedResources(resources);
};

// Sort resources by root, ex. Solar, Solar_1, BESS based on root ex. Solar, BESS.  Determines order of resources in UI.
export const sortResourcesByRoot = (resources) => {
  const possibleResources = ['Solar', 'BESS', 'Genset', 'Utility', 'Load'];

  let sortedResources = [];

  for (const possibleResource of possibleResources) {
    for (const resource of resources) {
      const resourceRoot = getResourceRoot(resource);
      if (resourceRoot === possibleResource) {
        sortedResources.push(resource);
      }
    }
  }

  return sortedResources;
};

export const getFlatAssets = (sitePowerData) => {
  if (!sitePowerData) {
    return [];
  }

  let flatAssets = [];

  for (const asset of sitePowerData.assemblies) {
    if (asset.array) {
      let assetStandard = asset;
      assetStandard.source = asset.name;
      flatAssets.push(assetStandard);

      flatAssets = flatAssets.concat(asset.array);
    } else {
      flatAssets.push(asset);
    }
  }

  return flatAssets;
};

// gets list of filtered and sorted resources available to other components, ex. Solar, Solar_1 BESS, Genset, etc.
export const getAvailableResources = (sitePowerData) => {
  const availableResources = getFlatAssets(sitePowerData).map((asset) => asset.source);
  const sortedResources = sortResourcesByRoot(availableResources);
  const selectedResources = getSelectedResources(sortedResources);
  return selectedResources;
};

// takes multiple datasources as input and returns a standardized list of metrics
export const getMetricsData = (sitePowerData, liveTimeSeriesData, todayTimeSeriesData) => {
  if (!(sitePowerData && liveTimeSeriesData.length && todayTimeSeriesData.length)) {
    return {};
  }

  let metricsData = {};
  const liveDataWindowSize = 2;

  const availableResources = getAvailableResources(sitePowerData);
  availableResources.forEach((name) => {
    const recentVals = liveTimeSeriesData.slice(-liveDataWindowSize).map((obj) => obj[name]);
    const lastVal = liveTimeSeriesData.slice(-1)[0][name];
    const lastGoodVal = recentVals
      .slice()
      .reverse()
      .find((v) => v !== null);
    const hasLiveData = lastGoodVal !== undefined;
    let val = lastVal ?? lastGoodVal ?? null;

    metricsData[name] = { val, hasLiveData, name };
  });

  const flatAssets = getFlatAssets(sitePowerData);

  if (availableResources.includes('BESS')) {
    metricsData['bessSoc'] = getBessSoc(sitePowerData);
  }

  metricsData = addAllSolarEnergyKwh(flatAssets, metricsData, todayTimeSeriesData);
  metricsData = addAllGensetCapacity(flatAssets, metricsData);

  return metricsData;
};

export const getCSVData = (timeSeriesData) => {
  const csvDataColumns = ['bucket', 'Solar', 'BESS', 'Utility'];

  const csvData = timeSeriesData.map((row) => {
    let newRow = {};
    csvDataColumns.forEach((col) => {
      newRow[col] = row[col];
    });
    return newRow;
  });

  return csvData;
};

export const getAvailableResourceParams = (availableResources, paramGetters) => {
  let params = [];
  availableResources.forEach((resource) => {
    const resourceRoot = getResourceRoot(resource);
    if (!paramGetters[resourceRoot]) {
      return;
    }
    params.push(paramGetters[resourceRoot](resource));
  });

  return params;
};

export const formatEnergyKwh = (energyKwh) => {
  const fractionDigitsKwh = 0;
  const fractionDigitsMwh = 2;

  if (energyKwh === null) return '--';

  let unit = 'kWh';
  let maximumFractionDigits = fractionDigitsKwh;
  if (energyKwh > 10000) {
    energyKwh = energyKwh / 1000;
    unit = 'MWh';
    maximumFractionDigits = fractionDigitsMwh;
  }
  const formattedEnergy =
    new Intl.NumberFormat('en-US', {
      maximumFractionDigits: maximumFractionDigits,
    }).format(energyKwh) +
    ' ' +
    unit;
  return formattedEnergy;
};
