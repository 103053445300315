import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { fatherAssemblies, keys } from '../Logic/FormatUtils';

/**
 * Renders a CustomLegend component displaying checkboxes for each entry in the payload.
 *
 * @param {object} props - The component props.
 * @param {Array} props.payload - The legend data payload.
 * @param {object} props.areaVisibility - The state object containing the visibility of each area.
 * @param {function} props.setAreaVisibility - The function to update the area visibility state.
 * @returns {JSX.Element} - The rendered CustomLegend component.
 */
const CustomLegend = ({
  payload,
  areaVisibility,
  setAreaVisibility,
  updateAnimationState,
  flags,
  timezone,
}) => {
  const [openEntries, setOpenEntries] = useState([]);

  const sortedPayload = payload.sort((a, b) => {
    return keys.indexOf(a.value.replace(/_\d+$/, '')) - keys.indexOf(b.value.replace(/_\d+$/, ''));
  });

  /**
   * Handles the checkbox change event.
   *
   * @param {object} event - The checkbox change event.
   */
  const handleCheckboxChange = (event) => {
    updateAnimationState(true);
    const { name, checked } = event.target;

    if (!fatherAssemblies.includes(name)) {
      const parentValue = name.split('_')[0];

      if (checked) {
        setAreaVisibility((prevVisibility) => ({
          ...prevVisibility,
          [parentValue]: false,
        }));
      }

      setAreaVisibility((prevVisibility) => ({
        ...prevVisibility,
        [name]: checked,
      }));
    } else {
      if (checked) {
        const childEntries = getChildEntries(name);
        childEntries.forEach((childEntry) => {
          setAreaVisibility((prevVisibility) => {
            return {
              ...prevVisibility,
              [childEntry]: false,
            };
          });
        });
      }

      setAreaVisibility((prevVisibility) => ({
        ...prevVisibility,
        [name]: checked,
      }));
    }
  };

  /**
   * Retrieves the child entries for a given parent value.
   *
   * @param {string} parentValue - The parent value to filter the child entries.
   * @returns {Array} - The array of child entries.
   */
  const getChildEntries = (parent) => {
    return sortedPayload
      .filter((entry) => entry.value.startsWith(parent + '_') && entry.value !== parent)
      .map((entry) => entry.value);
  };

  /**
   * Toggles the visibility of child entries and updates the openEntries state.
   *
   * @param {string} parent - The parent value.
   * @param {Array} openEntries - The array of open entries.
   */
  const hideShowChilds = (parent, openEntries) => {
    if (!openEntries.includes(parent)) {
      setOpenEntries((prevOpenEntries) => [...prevOpenEntries, parent]);
    } else {
      setOpenEntries((prevOpenEntries) => prevOpenEntries.filter((value) => value !== parent));
    }

    const childDiv = document.getElementsByClassName(`child-entries-${parent}`)[0];
    if (!openEntries.includes(parent)) {
      childDiv.style.visibility = 'hidden';
      childDiv.style.height = 0;
    } else {
      childDiv.style.removeProperty('height');
      childDiv.style.removeProperty('visibility');
    }
  };

  const fatherAssembly = sortedPayload.filter((entry) => !entry.value.includes('_'));

  return (
    <Box display="flex" flexDirection="column" justifyContent="center">
      {fatherAssembly.map((entry, index) => {
        const childEntries = getChildEntries(entry.value);
        return (
          <React.Fragment key={`legend-${index}`}>
            <div
              key={`legend-${index}`}
              className="legend-row"
              style={{ marginLeft: childEntries.length > 0 ? '-26px' : '0', marginTop: '2px' }}
            >
              {childEntries.length > 0 && (
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => hideShowChilds(entry.value.toLowerCase(), openEntries)}
                >
                  {!openEntries.includes(entry.value.toLowerCase()) ? (
                    <KeyboardArrowDownIcon />
                  ) : (
                    <KeyboardArrowRightIcon />
                  )}
                </IconButton>
              )}
              <label className="legend-checkbox">
                <input
                  type="checkbox"
                  name={entry.value}
                  checked={areaVisibility[entry.value]}
                  onChange={handleCheckboxChange}
                />
                <span
                  className="checkmark"
                  style={{
                    backgroundColor:
                      entry.value === 'Load'
                        ? '#fff'
                        : areaVisibility[entry.value]
                          ? entry.color
                          : '#fff',
                    border: entry.value === 'Load' ? '1px dashed #000000' : '1px solid #979797',
                    '--checkmark-border':
                      entry.value === 'Load' ? '1px solid #000' : '1px solid #fff',
                  }}
                ></span>
                <span className="legend-text">{entry.value.replace(/_/g, ' ')}</span>
              </label>
            </div>
            {fatherAssemblies.includes(entry.value) && (
              <div
                className={`child-entries-${entry.value.toLowerCase()}`}
                style={{ marginLeft: '20px' }}
              >
                {getChildEntries(entry.value).map((childEntry, childIndex) => (
                  <div key={`legend-${index}-child-${childIndex}`} className="legend-row">
                    <label className="legend-checkbox">
                      <input
                        type="checkbox"
                        name={childEntry}
                        checked={areaVisibility[childEntry]}
                        value={childEntry}
                        onChange={handleCheckboxChange}
                      />
                      <span
                        className="checkmark"
                        style={{
                          backgroundColor: areaVisibility[childEntry]
                            ? sortedPayload.find((item) => item.value === childEntry)?.color
                            : '#fff',
                          border:
                            entry.value === 'Load' ? '1px dashed #000000' : '1px solid #979797',
                          '--checkmark-border':
                            entry.value === 'Load' ? '1px solid #000' : '1px solid #fff',
                        }}
                      ></span>
                      <span className="legend-text" style={{ marginLeft: '10px' }}>
                        {childEntry.replace(/_/g, ' ')}
                      </span>
                    </label>
                  </div>
                ))}
              </div>
            )}
          </React.Fragment>
        );
      })}
      <div className="legend-timezone">{timezone}</div>
    </Box>
  );
};

CustomLegend.propTypes = {
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])
        .isRequired,
      color: PropTypes.string.isRequired,
    }),
  ).isRequired,
  areaVisibility: PropTypes.object,
  setAreaVisibility: PropTypes.func,
  updateAnimationState: PropTypes.func,
  flags: PropTypes.object,
  timezone: PropTypes.string,
};

export default CustomLegend;
