import PropTypes from 'prop-types';
import React from 'react';

import smsTheme from '../../../../style/theme';

const CustomizedTick = ({ x, y, payload, unit }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={2}
        fill={payload.value === 0 ? '#325182' : smsTheme.palette.axisText.main}
        fontSize={8}
        textAnchor="end"
      >
        {payload.value}
        {unit}
      </text>
    </g>
  );
};

CustomizedTick.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  payload: PropTypes.object,
  unit: PropTypes.string,
};

export default CustomizedTick;
