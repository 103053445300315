import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';

import useStore from '../state/KioskStore';
import smsTheme from '../style/theme';
import { formatResourceKey, getAvailableResourceParams } from './../lib/DataUtil';
import CSVDownload from './CSVDownload';
import Graph from './Graph/Graph';
import TimeSelect from './TimeSelect';

const getGraphsParams = (timeSeriesData, availableResources) => {
  const paramGetters = {
    Solar: (key) => {
      return {
        timeSeriesData: timeSeriesData,
        name: formatResourceKey(key),
        lineColor: smsTheme.palette.solar.main,
        areaColor: smsTheme.palette.solar.light,
        dataKey: key,
      };
    },
    BESS: (key) => ({
      timeSeriesData: timeSeriesData,
      name: formatResourceKey(key),
      lineColor: smsTheme.palette.bess.main,
      areaColor: smsTheme.palette.bess.light,
      positiveLabel: 'Discharge',
      negativeLabel: 'Charge',
      upIcon: '/icons/up-arrow-battery.svg',
      downIcon: '/icons/down-arrow-battery.svg',
      dataKey: key,
    }),
    Utility: (key) => ({
      timeSeriesData: timeSeriesData,
      name: 'Utility',
      lineColor: smsTheme.palette.utility.main,
      areaColor: smsTheme.palette.utility.light,
      positiveLabel: 'Import',
      negativeLabel: 'Export',
      upIcon: '/icons/up-arrow-grid.svg',
      downIcon: '/icons/down-arrow-grid.svg',
      dataKey: key,
    }),
    Genset: (key) => ({
      timeSeriesData: timeSeriesData,
      name: formatResourceKey(key),
      lineColor: smsTheme.palette.genSet.main,
      areaColor: smsTheme.palette.genSet.light,
      dataKey: key,
    }),
  };

  const graphParams = getAvailableResourceParams(availableResources, paramGetters);

  return graphParams;
};

const GraphList = ({ timeSeriesData, timeRange, availableResources }) => {
  const theme = useTheme();
  const store = useStore((state) => state);

  const styles = {
    graphContainer: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'stretch',
      boxSizing: 'border-box',
      display: 'flex',
      flex: 1,
    },
    graphRow: {
      display: 'flex',
      flex: 1,
      alignItems: 'stretch',
    },
    container: {
      ...theme.classes.card,
      width: '100%',
      px: '2.4vw',
      py: '0',
      position: 'relative',
    },
    timeRangeBoxContainer: {
      position: 'absolute',
      top: '1.25vw',
      right: '1.25vw',
      display: 'flex',
      gap: { xs: '7px', xl: '10px' },
      alignItems: 'stretch',
    },
  };

  const getGraphRow = (params) => {
    return (
      <React.Fragment key={params.name}>
        <Box sx={styles.graphRow}>
          <Graph {...params} />
        </Box>
      </React.Fragment>
    );
  };

  return (
    <Box sx={styles.container}>
      {timeSeriesData.length > 0 && timeRange && (
        <>
          <Box sx={styles.graphContainer} id="graph-container">
            {getGraphsParams(timeSeriesData, availableResources).map((params) =>
              getGraphRow(params),
            )}
          </Box>
          <Box sx={styles.timeRangeBoxContainer}>
            {store.mode === 'interactive' && <CSVDownload />}
            <TimeSelect />
          </Box>
        </>
      )}
    </Box>
  );
};

GraphList.propTypes = {
  timeSeriesData: PropTypes.array,
  timeRange: PropTypes.string,
  availableResources: PropTypes.array,
};

export default GraphList;
