import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import useStore from '../../state/KioskStore';

const TimeTick = ({ x, y, payload }) => {
  const store = useStore((state) => state);

  const formatTime = (unixTimestamp) => {
    const date = new Date(unixTimestamp);
    if (store.timeRangeKey === 'lastDay') {
      return moment(date).format('HH:mm');
    }
    return moment(date).format('MM/DD');
  };

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={15} fill={'#BFBFBF'} fontSize={14} textAnchor="middle">
        {formatTime(payload.value)}
      </text>
    </g>
  );
};

TimeTick.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  payload: PropTypes.object,
};

export default TimeTick;
