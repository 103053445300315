import axios from 'axios';

export const baseUrlPresentation = process.env.REACT_APP_SMS_NOC_PRESENTATION;

export const getTimeSeriesPublic = async (siteUuid, startTime, stopTime, interval) => {
  try {
    const params = {
      interval: interval,
      start_time: startTime,
      stop_time: stopTime,
    };

    const response = await axios.get(`${baseUrlPresentation}/public/site/${siteUuid}/time-series`, {
      params,
    });

    return response.data.time_series;
  } catch (err) {
    console.error('Problem getting time series');
    throw err;
  }
};

export const getTimeSeries = async (siteUuid, startTime, stopTime, interval) => {
  try {
    const params = {
      interval: interval,
      start_time: startTime,
      stop_time: stopTime,
    };

    const response = await axios.get(`${baseUrlPresentation}/site/${siteUuid}/time-series`, {
      params,
    });

    return response.data.time_series;
  } catch (err) {
    console.error('Problem getting time series');
    throw err;
  }
};

export const getSitePowerCapacityPublic = async (siteUuid) => {
  try {
    const response = await axios.get(
      `${baseUrlPresentation}/public/site/${siteUuid}/power/capacity`,
    );
    return response.data;
  } catch (err) {
    console.error('Problem getting site power capacity');
    throw err;
  }
};

export const getSiteMetadataPublic = async (siteUuid) => {
  try {
    const response = await axios.get(`${baseUrlPresentation}/public/site/${siteUuid}`);
    return response.data;
  } catch (err) {
    console.error('Problem getting site metadata');
    throw err;
  }
};

export const getCustomerSitesPublic = async (customerUuid) => {
  try {
    const response = await axios.get(
      `${baseUrlPresentation}/public/customer/${customerUuid}/public_sites`,
    );
    return response.data;
  } catch (err) {
    console.error('Problem getting customer sites');
    throw err;
  }
};

export const getUserProfile = () => {
  return axios.get(`${baseUrlPresentation}/user`).then((res) => res.data);
};

export const getUserProfileQuery = () => {
  return {
    queryKey: ['user-profile'],
    queryFn: () => getUserProfile(),
  };
};

export const getUser = async () => {
  try {
    const response = await axios.get(`${baseUrlPresentation}/user`);
    return response.data;
  } catch (err) {
    console.error('Problem getting user data');
    throw err;
  }
};

export const getSitePowerCapacity = async (siteUuid) => {
  const res = await axios.get(`${baseUrlPresentation}/site/${siteUuid}/power/capacity`);
  return res.data;
};

export const getSitePowerCapacityQuery = (siteUuid) => {
  return {
    queryKey: ['site-power-capacity', siteUuid],
    queryFn: () => getSitePowerCapacity(siteUuid),
    refetchInterval: 15000,
  };
};

export const getSiteMetadata = async (siteUuid) => {
  const res = await axios.get(`${baseUrlPresentation}/site/${siteUuid}`);
  return res.data;
};

export const getSiteMetadataQuery = (siteUuid) => {
  return {
    queryKey: ['site-metadata', siteUuid],
    queryFn: () => getSiteMetadata(siteUuid),
  };
};

export const getPortfolioPowerProduction = async () => {
  const response = await axios.get(`${baseUrlPresentation}/portfolio/power/production`);
  return response.data;
};

export const getPortfolioPowerProductionQuery = () => {
  return {
    queryKey: ['site-portfolio'],
    queryFn: () => getPortfolioPowerProduction(),
  };
};

const getSiteAssemblies = (siteUuid, interval) => {
  const params = { interval: interval };
  return axios
    .get(`${baseUrlPresentation}/site/${siteUuid}/assemblies`, { params })
    .then((res) => res.data);
};

export const getSiteAssembliesQuery = (siteUuid) => {
  return {
    queryKey: ['site-assemblies-' + siteUuid],
    queryFn: () => getSiteAssemblies(siteUuid, 120),
  };
};

export const getSiteTimeSeries = (siteUuid, start, stop, interval) => {
  const params = {
    interval: interval,
    start_time: start,
    stop_time: stop,
  };
  return axios
    .get(`${baseUrlPresentation}/site/${siteUuid}/time-series`, { params })
    .then((res) => res.data);
};

export const getSiteTimeSeriesQuery = (siteUuid, start, stop, interval) => {
  return {
    queryKey: ['site-time-series-' + siteUuid],
    queryFn: () => getSiteTimeSeries(siteUuid, start, stop, interval),
    // Refetch the data every 15 seconds
    refetchInterval: 15000,
  };
};

export const getTimeSeriesMetrics = (siteUuid, start, stop, interval, metricsUuids) => {
  const params = {
    start_time: start,
    stop_time: stop,
    interval: interval,
    metrics: metricsUuids.toString(),
  };
  return axios
    .get(`${baseUrlPresentation}/site/${siteUuid}/time-series/metrics`, { params })
    .then((res) => res.data);
};

const getSiteEnergyProduction = (siteUuid, start, stop, interval) => {
  const params = {
    interval: interval,
    start_time: start,
    stop_time: stop,
  };
  return axios
    .get(`${baseUrlPresentation}/site/${siteUuid}/energy/production`, { params })
    .then((res) => res.data);
};

export const getSiteEnergyProductionQuery = (siteUuid, start, stop, interval) => {
  return {
    queryKey: ['site-energy-production-' + siteUuid],
    queryFn: () => getSiteEnergyProduction(siteUuid, start, stop, interval),
    refetchInterval: 15000,
  };
};

const getAlarms = async (siteUuid) => {
  try {
    const res = await axios.get(`${baseUrlPresentation}/site/${siteUuid}/alarm`);
    return res.data.alarms;
  } catch (err) {
    if (err.response && err.response.status === 404) {
      return [];
    }
    throw err;
  }
};

export const getAlarmsQuery = (siteUuid) => {
  return {
    queryKey: ['site-alarms', siteUuid],
    queryFn: () => getAlarms(siteUuid),
  };
};

export const putUser = async (userDetails) => {
  const res = await axios.put(`${baseUrlPresentation}/user`, userDetails);
  return res.data;
};
