import PropTypes from 'prop-types';
import React from 'react';

const H2 = ({ children, ...rest }) => (
  <h2 style={{ color: 'black', fontSize: '16px', fontWeight: 400 }} {...rest}>
    {children}
  </h2>
);

H2.propTypes = {
  children: PropTypes.node,
};

const H3 = ({ children, ...rest }) => (
  <h3 style={{ color: 'black', fontSize: '14px', fontWeight: 400 }} {...rest}>
    {children}
  </h3>
);

H3.propTypes = {
  children: PropTypes.node,
};

const P = ({ children, ...rest }) => (
  <span style={{ color: '#868686', fontSize: '14px' }} {...rest}>
    {children}
  </span>
);

P.propTypes = {
  children: PropTypes.node,
};

const LI = ({ children, ...rest }) => (
  <li style={{ color: '#868686', fontSize: '14px' }} {...rest}>
    {children}
  </li>
);

LI.propTypes = {
  children: PropTypes.node,
};

const EM = ({ children, ...rest }) => (
  <em style={{ color: '#868686', fontSize: '14px' }} {...rest}>
    {children}
  </em>
);

EM.propTypes = {
  children: PropTypes.node,
};

const A = ({ children, ...rest }) => (
  <a style={{ color: '#868686', fontSize: '14px' }} {...rest}>
    {children}
  </a>
);

A.propTypes = {
  children: PropTypes.node,
};

export { A, EM, H2, H3, LI, P };
