import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function NotAuthorized() {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <Box sx={{ pt: '4rem', display: 'flex', justifyContent: 'center', width: '100%' }}>
        <img src="/scale-logo.svg" alt="scale microgrids logo" style={{ width: '20rem' }} />
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center" sx={{ flexGrow: 1 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '25rem',
            pb: '10rem',
            pt: '2rem',
          }}
        >
          <Typography variant="h4" sx={{ mb: '1rem' }}>
            See an admin for access
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
