import { getSiteTimeCombos } from '../components/Kiosk/KioskUtil';
import { getAvailableResources, getMetricsData } from '../lib/DataUtil';
import { timeRanges } from '../lib/DateUtil';
import {
  getCustomerSitesPublic,
  getPortfolioPowerProduction,
  getSiteMetadata,
  getSiteMetadataPublic,
  getSitePowerCapacity,
  getSitePowerCapacityPublic,
  getTimeSeries,
  getTimeSeriesPublic,
} from '../lib/Queries';
import useUserStore from './UserStore';
const clearTimeDataHelper = (set) => {
  set({
    metricsData: null,
    timeSeriesData: [],
    displayTimeRangeKey: null,
  });
};

const loadTimeDataHelper = async (get, set) => {
  const { siteUuid, timeRangeKey, mode } = get();

  const isLoggedIn = useUserStore.getState().user;

  if (mode === 'interactive') {
    // don't show loading spinner in cycling kiosk mode
    set({ isLoading: true });

    // clear old data so it is not displayed while new data is loaded
    clearTimeDataHelper(set);
  }

  const siteMetadataGetter = isLoggedIn ? getSiteMetadata : getSiteMetadataPublic;
  const siteMetadata = await siteMetadataGetter(siteUuid);

  const timeSeriesGetter = isLoggedIn ? getTimeSeries : getTimeSeriesPublic;
  const sitePowerCapacityGetter = isLoggedIn ? getSitePowerCapacity : getSitePowerCapacityPublic;

  const [timeSeriesData, todayTimeSeriesData, liveTimeSeriesData, sitePowerData] =
    await Promise.all([
      timeSeriesGetter(
        siteUuid,
        timeRanges[timeRangeKey].getRangeStart(siteMetadata.timezone),
        timeRanges[timeRangeKey].getRangeStop(siteMetadata.timezone),
        timeRanges[timeRangeKey].interval_seconds,
      ),
      timeSeriesGetter(
        siteUuid,
        timeRanges.todaySoFar.getRangeStart(siteMetadata.timezone),
        timeRanges.todaySoFar.getRangeStop(siteMetadata.timezone),
        timeRanges.todaySoFar.interval_seconds,
      ),
      timeSeriesGetter(
        siteUuid,
        timeRanges.last18Minutes.getRangeStart(siteMetadata.timezone),
        timeRanges.last18Minutes.getRangeStop(siteMetadata.timezone),
        timeRanges.last18Minutes.interval_seconds,
      ),

      sitePowerCapacityGetter(siteUuid),
    ]);

  const metricsData = getMetricsData(sitePowerData, liveTimeSeriesData, todayTimeSeriesData);
  const availableResources = getAvailableResources(sitePowerData);

  set({
    metricsData,
    availableResources,
    timeSeriesData,
    siteMetadata,
    displayTimeRangeKey: timeRangeKey,
    isLoading: false,
    hasError: false,
  });
};

const loadCustomerSitesHelper = async (set, get, customer, site = null) => {
  if (useUserStore.getState().user && !customer) {
    const data = await getPortfolioPowerProduction();
    const sites = data.sites.map((site) => ({
      uuid: site.uuid,
      name: site.name,
    }));
    set({
      customerSites: sites,
      siteUuid: site || sites[0].uuid,
    });
  } else if (customer) {
    const customerMetadata = await getCustomerSitesPublic(customer);
    set({
      customerSites: customerMetadata.public_sites,
      siteUuid: site || customerMetadata.public_sites[0].uuid,
    });
  } else {
    get().login(window.location.origin + '/kiosk');
  }
};

const setNextConfigComboHelper = async (get, set) => {
  const { customerSites, siteUuid, timeRangeKey, cycleTimeRangeKeys } = get();
  const combos = getSiteTimeCombos(customerSites, cycleTimeRangeKeys);
  const currComboKey = `${siteUuid}_${timeRangeKey}`;
  const currComboIndex = combos.findIndex((combo) => combo.key === currComboKey);
  const nextComboIndex = (currComboIndex + 1) % combos.length;
  const nextCombo = combos[nextComboIndex];

  set({
    siteUuid: nextCombo.siteUuid,
    timeRangeKey: nextCombo.timeRangeKey,
  });
};

const setErrorHelper = (set, error) => {
  if (!error) {
    set({ hasError: false });
    return;
  }
  set({ hasError: true });
  setTimeout(() => {
    set({ hasError: false });
  }, 10 * 1000);
};

export {
  clearTimeDataHelper,
  loadCustomerSitesHelper,
  loadTimeDataHelper as loadKioskDataHelper,
  setErrorHelper,
  setNextConfigComboHelper,
};
