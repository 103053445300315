// CustomLabel.jsx
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';

const GraphLabel = ({ viewBox, upIcon, downIcon, positiveLabel, negativeLabel, lineColor }) => {
  const maxforeignObjectHeight = 200;

  const styles = {
    foreignObject: {
      height: `${maxforeignObjectHeight}px`,
      width: 'calc(100% - 4rem)',
      textAlign: 'right',
    },
    labelsContainer: {
      display: 'flex',
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'end',
      flexDirection: 'column',
      gap: '.7rem',
      color: lineColor,
    },
    labelBackground: {
      py: '0.25rem',
      px: '0.5rem',
      backgroundColor: 'rgba(255, 255, 255, 0.80)',
      borderRadius: '5px',
      whiteSpace: 'nowrap',
    },
    imgContainer: {
      width: { xs: '.5rem', xl: '.75rem' },
      marginRight: '.7rem',
      display: 'inline-block',
    },
    type: {
      display: 'inline-block',
    },
  };

  function renderLabelBox(icon, label, name, style = {}) {
    return (
      <Box sx={styles.labelBackground}>
        <Box sx={styles.imgContainer}>
          <img src={icon} alt={`${name} icon`} style={{ width: '100%' }} />
        </Box>
        <Typography variant="h5" style={styles.type}>
          {label}
        </Typography>
      </Box>
    );
  }

  return (
    <foreignObject
      style={styles.foreignObject}
      x={viewBox.x}
      y={viewBox.y - maxforeignObjectHeight / 2}
    >
      <Box
        xmlns="http://www.w3.org/1999/xhtml"
        className="custom-label"
        sx={styles.labelsContainer}
      >
        <Box sx={{ mt: '.6rem' }}>{renderLabelBox(upIcon, positiveLabel, 'Positive')}</Box>
        {renderLabelBox(downIcon, negativeLabel, 'Negative')}
      </Box>
    </foreignObject>
  );
};

GraphLabel.propTypes = {
  viewBox: PropTypes.object,
  upIcon: PropTypes.string,
  downIcon: PropTypes.string,
  positiveLabel: PropTypes.string,
  negativeLabel: PropTypes.string,
  lineColor: PropTypes.string,
};

export default GraphLabel;
