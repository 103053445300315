export const getSiteTimeCombos = (sites, times) => {
  let combos = [];
  sites.forEach((site) => {
    times.forEach((timeRangeKey) => {
      combos.push({
        siteUuid: site.uuid,
        timeRangeKey,
        key: `${site.uuid}_${timeRangeKey}`,
      });
    });
  });
  return combos;
};
