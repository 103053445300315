import { Card, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';

import { useMobilePassThrough } from '../lib/StyleUtil';
import smsTheme from '../style/theme';
import { formatResourceKey, getAvailableResourceParams } from './../lib/DataUtil';
import Metric from './Metric/Metric';

export const getMetricsParams = (metricsData, availableResources) => {
  const paramGetters = {
    Solar: (key) => ({
      name: formatResourceKey(key),
      value: metricsData[key].val,
      hasLiveData: metricsData[key].hasLiveData,
      iconUrl: '/icons/solar-icon.svg',
      mobileIconUrl: '/icons/solar-icon-mobile.svg',
      unit: 'kW',
      highlightColor: smsTheme.palette.genSet.main,
      key,
    }),
    BESS: (key) => ({
      name: 'Battery',
      value: metricsData[key].val,
      hasLiveData: metricsData['BESS'].hasLiveData,
      iconUrl: '/icons/battery-icon.svg',
      mobileIconUrl: '/icons/battery-icon-mobile.svg',
      unit: 'kW',
      highlightColor: smsTheme.palette.genSet.main,
      key,
    }),
    Utility: (key) => ({
      name: 'Grid',
      value: metricsData['Utility'].val,
      hasLiveData: metricsData['Utility'].hasLiveData,
      iconUrl: '/icons/grid-icon.svg',
      mobileIconUrl: '/icons/grid-icon-mobile.svg',
      unit: 'kW',
      highlightColor: smsTheme.palette.genSet.main,
      key,
    }),
    Genset: (key) => ({
      name: formatResourceKey(key),
      value: metricsData[key].val,
      hasLiveData: metricsData[key].hasLiveData,
      iconUrl: '/icons/generator-icon.svg',
      mobileIconUrl: '/icons/generator-icon-mobile.svg',
      unit: 'kW',
      highlightColor: smsTheme.palette.genSet.main,
      key,
    }),
  };

  let metricsParams = getAvailableResourceParams(availableResources, paramGetters);

  const compactMode = metricsParams.length >= 4;

  metricsParams = metricsParams.map((param) => {
    return { ...param, compactMode };
  });

  return metricsParams;
};

const LiveMetrics = ({ metricsData, availableResources }) => {
  const theme = useTheme();
  const passThrough = useMobilePassThrough();

  const styles = {
    card: {
      boxShadow: '4px 0px 30px 0px rgba(0, 0, 0, 0.03137254901960784)',
      px: '2.81vw',
      width: '100%',
      margin: '-1px',
    },
    mobileCard: {
      ...theme.classes.box,
      backgroundColor: 'white',
    },
    outerGrid: {
      flexDirection: 'column',
      justifyContent: 'space-between',
      margin: 0,
      display: 'flex',
    },
  };

  return (
    <Card
      variant="outlined"
      sx={[styles.card, theme.classes.card, passThrough]}
      id="live-metrics-root-card"
    >
      {metricsData && (
        <Grid container id="metrics-grid" sx={[styles.outerGrid, passThrough]}>
          {getMetricsParams(metricsData, availableResources).map((params) => (
            <Grid item key={params.name} sx={[passThrough]}>
              <Metric {...params} />
            </Grid>
          ))}
        </Grid>
      )}
    </Card>
  );
};

LiveMetrics.propTypes = {
  metricsData: PropTypes.object,
  availableResources: PropTypes.array,
};

export default LiveMetrics;
