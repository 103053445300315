import { Box } from '@mui/material';
import { useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import useUserStore from '../../state/UserStore';
import AgreementDialog from './../AgreementDialog/AgreementDialog';
import { privacyText, privacyTitle, termsText, termsTitle } from './AgreementText';

const AgreementPage = () => {
  const userStore = useUserStore((state) => state);
  const [showTerms, setShowTerms] = useState(true);
  const [showPrivacy, setShowPrivacy] = useState(false);
  const { logout: authLogout } = useAuth0();

  const handleAgreeTerms = () => {
    setShowTerms(false);
    setShowPrivacy(true);
  };
  const handleAgreePrivacy = () => {
    userStore.setTermsAgreement();
  };

  const logout = () => {
    authLogout({ logoutParams: { returnTo: window.location.href } });
  };

  return (
    <Box>
      <AgreementDialog
        handleAgree={handleAgreeTerms}
        handleDecline={logout}
        show={showTerms}
        bodyMarkdown={termsText}
        title={termsTitle}
      />
      <AgreementDialog
        handleAgree={handleAgreePrivacy}
        handleDecline={logout}
        show={showPrivacy}
        bodyMarkdown={privacyText}
        title={privacyTitle}
      />
    </Box>
  );
};

AgreementPage.propTypes = {};

export default AgreementPage;
