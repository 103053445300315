import { Box, Card, Divider, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useMobile, useMobilePassThrough } from '../../lib/StyleUtil';
import useStore from '../../state/KioskStore';
import CumulativeMetrics from '../CumulativeMetrics';
import ErrorCallout from '../ErrorCallout';
import GraphList from '../GraphList';
import LiveMetrics from '../LiveMetrics';
import LoadingSpinner from '../LoadingSpinner';
import ModeSelect from '../ModeSelect';

const Kiosk = ({ mode }) => {
  const store = useStore((state) => state);
  const [searchParams, setSearchParams] = useSearchParams();

  const timeCycleIntervalDefault = 20;
  const timeCycleInterval = searchParams.get('cycle-interval') || timeCycleIntervalDefault;
  const theme = useTheme();
  const isMobile = useMobile();
  const passThrough = useMobilePassThrough();

  const setInteractiveMode = () => {
    if (mode === 'interactive') return;

    store.reset();
    store.setMode('interactive');

    searchParams.delete('cycle-interval');
    searchParams.set('mode', 'interactive');
    setSearchParams(searchParams);
  };

  const handleEscapePress = (event) => {
    if (event.key === 'Escape') {
      setInteractiveMode();
    }
  };

  const setupInteractiveParams = () => {
    const timeRangeKey = searchParams.get('time-range') || store.timeRangeKey;
    const siteUuid = searchParams.get('site') || store.siteUuid;

    store.setTimeRangeKey(timeRangeKey);
    store.setSiteUuid(siteUuid);
    if (!isMobile) searchParams.set('time-range', timeRangeKey);
    searchParams.set('site', siteUuid);
    setSearchParams(searchParams);
  };

  const setupKioskParams = () => {
    searchParams.set('cycle-interval', timeCycleInterval);
    setSearchParams(searchParams);
  };

  const processParams = () => {
    mode === 'kiosk' ? setupKioskParams() : setupInteractiveParams();
  };

  const setSiteTimeParamsFromState = () => {
    if (isMobile) return;
    searchParams.set('time-range', store.timeRangeKey);
    searchParams.set('site', store.siteUuid);
    setSearchParams(searchParams);
  };

  const setupTimeData = async () => {
    await store.loadTimeData(store.siteUuid, store.timeRangeKey);
    if (mode === 'interactive') setSiteTimeParamsFromState();
  };

  const redirectMobileKiosk = () => {
    if (mode === 'kiosk' && isMobile) {
      setInteractiveMode();
    }
  };

  const getMode = () => {
    if (store.mode) return;

    const start_mode = searchParams.get('mode') || 'interactive';
    store.setMode(start_mode);
  };

  useEffect(() => {
    getMode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (mode !== 'kiosk') return;

    const interval = setInterval(() => {
      store.setNextConfigCombo();
    }, timeCycleInterval * 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const customer = searchParams.get('customer');
    const initialSite = searchParams.get('site');
    store.loadCustomerSites(customer, initialSite);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (store.siteUuid && store.timeRangeKey && !store.hasError) {
      setupTimeData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.siteUuid, store.timeRangeKey, store.hasError]);

  useEffect(() => {
    window.addEventListener('keydown', handleEscapePress);

    return () => {
      window.removeEventListener('keydown', handleEscapePress);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    searchParams.set('mode', mode);
    setSearchParams(searchParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  useEffect(() => {
    if (store.customerSites && store.customerSites.length > 0) {
      processParams();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.customerSites]);

  useEffect(() => {
    redirectMobileKiosk();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile, mode]);

  const styles = {
    mainRowsContainer: {
      width: '100%',
      minHeight: '100vh',
      margin: 0,
      padding: '2.4vw',
      pt: '1.66vw',
      gap: '.83vw',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'stretch',
      boxSizing: 'border-box',
      display: 'flex',
      backgroundColor: theme.palette.background.main,
    },
    mainRowsContainerMobile: {
      px: '20px',
      py: '43px',
    },
    headerRow: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'space-between',
    },
    contentRow: {
      display: 'flex',
      alignItems: 'stretch',
      justifyContent: 'space-between',
      flex: 1,
    },
    liveMetricsColumn: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'stretch',
      flexBasis: '18vw',
    },
    graphColumn: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'stretch',
      position: 'relative',
      flex: 1,
    },
    rightColumn: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'stretch',
      flexBasis: '24vw',
    },
    metricsGraphCard: {
      width: '100%',
      py: 0,
      mr: '.83vw',
    },
    loadingContainer: {
      display: 'flex',
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
  };

  const renderLoadingLayout = (size) => (
    <Box sx={styles.loadingContainer}>
      <LoadingSpinner size={size} />
    </Box>
  );

  const renderGraphColumn = () => {
    if (store.hasError) return <ErrorCallout showRetry={false} />;
    if (store.isLoading) return renderLoadingLayout(113);
    return (
      <GraphList
        timeSeriesData={store.timeSeriesData}
        timeRange={store.displayTimeRangeKey}
        availableResources={store.availableResources}
      />
    );
  };

  const getKioskSiteHeading = () => (
    <Typography
      variant="h3"
      sx={{ color: theme.palette.secondary.main }}
      data-testid="static-kiosk-heading"
    >
      {store.siteMetadata.site_name}
    </Typography>
  );

  const getSiteHeading = () => {
    if (!store.siteMetadata) return <div>&nbsp;</div>;

    return mode === 'kiosk' ? getKioskSiteHeading() : <ModeSelect />;
  };

  const getLogo = () => (
    <img src="/scale-logo.svg" alt="scale microgrids logo" style={{ width: '100%' }} />
  );

  if (!store.customerSites) {
    return <></>;
  }

  return (
    <Box
      spacing={3}
      sx={[styles.mainRowsContainer, isMobile && styles.mainRowsContainerMobile]}
      id="outer-grid"
    >
      {isMobile && (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box sx={{ width: '50%', pb: '23px' }} data-testid="mobile-logo">
            {getLogo()}
          </Box>
          <Divider sx={{ width: '100%', mb: '23px' }} />
        </Box>
      )}
      <Box sx={styles.headerRow}>
        {getSiteHeading()}
        {!isMobile && <Box sx={{ width: '8.54vw', maxWidth: '164px' }}>{getLogo()}</Box>}
      </Box>
      {store.isLoading && isMobile && !store.hasError && (
        <Box sx={{ mt: '1rem' }}>{renderLoadingLayout(80)}</Box>
      )}
      {store.hasError && isMobile && (
        <Box sx={{ mt: '1rem' }}>
          <ErrorCallout showRetry={false} />
        </Box>
      )}
      <Box sx={[styles.contentRow, passThrough]} id="content-row">
        <Card
          variant="outlined"
          sx={[theme.classes.card, styles.metricsGraphCard, passThrough]}
          id="live-metrics-root-card"
        >
          <Box sx={[styles.liveMetricsColumn, passThrough]}>
            <LiveMetrics
              metricsData={store.metricsData}
              availableResources={store.availableResources}
            />
          </Box>
          {!isMobile && <Box sx={styles.graphColumn}>{renderGraphColumn()}</Box>}
        </Card>
        <Box sx={styles.rightColumn} id="right-column">
          <CumulativeMetrics
            metricsData={store.metricsData}
            availableResources={store.availableResources}
          />
        </Box>
      </Box>
    </Box>
  );
};

Kiosk.propTypes = {
  mode: PropTypes.string,
};

export default Kiosk;
