import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Box, ClickAwayListener } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { formatEnergyKwh } from './../../../../lib/DataUtil';
import { getSiteEnergyProductionQuery } from './../../../../lib/Queries';

const DropdownEnergy = ({ site, displayRange }) => {
  const [open, setOpen] = useState(false);
  const [energy, setEnergy] = useState({});

  const { isLoading, error, data, refetch } = useQuery({
    ...getSiteEnergyProductionQuery(
      site.uuid,
      displayRange.start,
      displayRange.stop,
      displayRange.interval,
    ),
  });

  useEffect(() => {
    if (data) {
      setEnergy({ ...data });
    }
  }, [data]);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayRange]);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Box width="100%" display="flex">
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <List
          className="select-box"
          sx={{
            width: '100%',
            height: '44px',
            maxWidth: 278,
            position: 'absolute',
            backgroundColor: '#fff',
            zIndex: 999,
          }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          <ListItemButton
            onClick={handleClick}
            sx={{
              width: '278px',
              paddingTop: '0px',
              top: '2px !important',
              color: 'rgba(255,255,255,.8)',
            }}
          >
            <ListItemText
              key={0}
              primary={
                <>
                  <span className="select-text">Total Production: </span>
                  <span style={{ color: '#78BB39' }}>
                    {isLoading ? 'Loading...' : formatEnergyKwh(energy.site_energy)}
                  </span>
                </>
              }
            />
            {open ? (
              <ExpandLess style={{ color: '#000' }} />
            ) : (
              <ExpandMore style={{ color: '#000' }} />
            )}
          </ListItemButton>
          <Collapse in={open} timeout="auto" unmountOnExit className="collapse-root-with-shadow">
            <List component="div" disablePadding>
              {energy.der_groups &&
                energy.der_groups.map((assembly, index) => {
                  return (
                    <ListItemText
                      sx={{ paddingLeft: '15px', paddingTop: '4px', paddingBottom: '4px' }}
                      key={index}
                      primary={
                        <>
                          <span className="select-text">{assembly.group_assemblies}: </span>
                          <span style={{ color: '#78BB39' }}>
                            {error
                              ? 'Error...'
                              : isLoading
                                ? 'Loading...'
                                : formatEnergyKwh(assembly.energy)}
                          </span>
                        </>
                      }
                    />
                  );
                })}
            </List>
          </Collapse>
        </List>
      </ClickAwayListener>
    </Box>
  );
};

DropdownEnergy.propTypes = {
  site: PropTypes.object.isRequired,
  displayRange: PropTypes.object,
  manualRefresh: PropTypes.bool,
};

export default DropdownEnergy;
