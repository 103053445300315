import { Box, MenuItem, Select, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';

import useStore from '../state/KioskStore';

const ModeSelect = () => {
  const theme = useTheme();
  const store = useStore((state) => state);
  const timeRangeTextMapping = { lastDay: 'Last 24hrs', lastWeek: 'Last 7 days' };

  const styles = {
    select: {
      '&.MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline':
        {
          border: '1px solid',
          borderColor: theme.classes.box.borderColor,
        },
      '& .MuiSelect-select': { py: '.677vw', pr: '2.5rem !important' },
    },
    timeRangeBox: {
      ...theme.classes.box,
      px: '1rem',
      py: '.677vw',
    },
    text: {
      color: '#939393',
    },
    mainBox: {
      backgroundColor: 'RGBA(255, 255, 255, 0.8)',
    },
  };

  const handleChange = (event) => {
    store.setTimeRangeKey(event.target.value);
  };

  const getMenuItem = (value, displayText) => {
    const selected = value === store.timeRangeKey;
    return (
      <MenuItem
        value={value}
        sx={{
          '&.Mui-selected': {
            backgroundColor: 'rgba(217, 217, 217, 0.1) !important',
            flexWrap: 'nowrap',
            display: 'flex',
            justifyConent: 'flex-start',
          },
          px: '1rem !important',
        }}
        key={value}
      >
        <Typography
          variant="h5"
          sx={[
            styles.text,
            {
              color: selected ? theme.palette.secondary.main : styles.text.color,
            },
          ]}
        >
          {displayText}
        </Typography>
        {selected && (
          <Box sx={{ ml: '.75rem' }}>
            <img src={'/icons/select-checkmark-icon.svg'} alt={'select checkmark icon'} />
          </Box>
        )}
      </MenuItem>
    );
  };

  const getSelectedText = () => {
    return (
      <Typography variant="h5" sx={styles.text}>
        {timeRangeTextMapping[store.displayTimeRangeKey]}
      </Typography>
    );
  };

  const customIcon = ({ className }) => {
    const isOpen = className.includes('MuiSelect-iconOpen');

    return (
      <Box sx={[theme.classes.customSelectIcon, { right: '1rem' }]}>
        <img
          src={isOpen ? '/icons/select-close-gray-icon.svg' : '/icons/select-open-gray-icon.svg'}
          alt={isOpen ? 'open mode select box icon' : 'close mode select icon'}
        />
      </Box>
    );
  };

  customIcon.propTypes = { className: PropTypes.string };

  return store.mode === 'kiosk' ? (
    <Box sx={[styles.timeRangeBox, styles.text, styles.mainBox]} data-testid="static-time-display">
      {getSelectedText()}
    </Box>
  ) : (
    <Select
      value={store.timeRangeKey}
      onChange={handleChange}
      sx={[styles.select, styles.mainBox]}
      renderValue={() => getSelectedText()}
      IconComponent={customIcon}
      data-testid="time-select"
    >
      {store.cycleTimeRangeKeys.map((item) => getMenuItem(item, timeRangeTextMapping[item]))}
    </Select>
  );
};

export default ModeSelect;
