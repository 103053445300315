import PropTypes from 'prop-types';
import React from 'react';

import useStore from '../state/KioskStore';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.promiseRejectionHandler = this.promiseRejectionHandler.bind(this);
    this.handleKioskError = this.handleKioskError.bind(this);
  }

  handleKioskError() {
    useStore.getState().clearTimeData();
    useStore.getState().setError(true);
    useStore.getState().setIsLoading(false);
  }

  componentDidCatch(error, errorInfo) {
    this.handleKioskError();
  }

  promiseRejectionHandler(event) {
    this.handleKioskError();
  }

  componentDidMount() {
    window.addEventListener('unhandledrejection', this.promiseRejectionHandler);
  }

  componentWillUnmount() {
    window.removeEventListener('unhandledrejection', this.promiseRejectionHandler);
  }

  render() {
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
};

export default ErrorBoundary;
