import CloseIcon from '@mui/icons-material/Close';
import { Box, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';
import Markdown from 'react-markdown';

import { A, EM, H2, H3, LI, P } from './MarkdownComponents';

const AgreementDialog = ({
  handleAgree,
  handleDecline,
  show,
  bodyMarkdown,
  title,
  interactive = true,
  onClose,
}) => {
  const theme = useTheme();
  const markdownFormatted = bodyMarkdown.replace(/\n/gi, '  \n&nbsp;  \n');

  const styles = {
    markdownBodyContainer: {
      overflowY: 'auto',
      mb: '48px',
      pr: '20px',

      '&::-webkit-scrollbar': {
        width: '0.4em',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#A9A9A9',
        outline: 'none',
      },
    },
    dialogBox: { width: '60vw', maxWidth: '800px', maxHeight: '70vh', py: '48px', px: '64px' },
  };

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={show}
      PaperProps={{
        sx: [theme.classes.box, { ...styles.dialogBox }],
      }}
      slotProps={{
        backdrop: {
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0)',
          },
        },
      }}
      onClose={interactive ? null : onClose}
    >
      {!interactive && (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
      <Typography variant="h5Portal" sx={{ color: '#2B2B2B', mb: '40px' }}>
        {title}
      </Typography>
      <Box sx={styles.markdownBodyContainer}>
        <Markdown
          components={{
            h2: H2,
            h3: H3,
            p: P,
            li: LI,
            em: EM,
            a: A,
          }}
          parserOptions={{ commonmark: true }}
        >
          {markdownFormatted}
        </Markdown>
      </Box>

      {interactive && (
        <Box sx={{ display: 'flex' }}>
          <Button
            onClick={handleDecline}
            disableElevation
            sx={{ mr: '1rem' }}
            variant="outlined"
            color="secondary"
            data-testid="login-button"
          >
            Decline
          </Button>
          <Button
            onClick={handleAgree}
            disableElevation
            variant="contained"
            color="secondary"
            data-testid="login-button"
          >
            Agree
          </Button>
        </Box>
      )}
    </Dialog>
  );
};

AgreementDialog.propTypes = {
  handleAgree: PropTypes.func,
  handleDecline: PropTypes.func,
  show: PropTypes.bool,
  bodyMarkdown: PropTypes.string,
  title: PropTypes.string,
  interactive: PropTypes.bool,
  onClose: PropTypes.func,
};

export default AgreementDialog;
