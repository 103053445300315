/*
 * Copyright © Scale Microgrid Solutions Operating, LLC [2023].
 * All rights reserved.
 *
 * SPDX-FileCopyrightText: ©2023 Scale Microgrid Solutions Operating, LLC <legal@scalemicrogrids.com>
 */
import { createTheme, getContrastRatio } from '@mui/material/styles';

const boxBorderColor = '#E1E2E4';

let theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 1919,
    },
  },
  palette: {
    primary: {
      main: '#3AACFF', //Button
      contrastText: getContrastRatio('#3AACFF', '#fff') > 1 ? '#fff' : '#000',
    },
    secondary: {
      main: '#325182', //ScaleOS Blue
      light: '#7F99C2', //ScaleOS Blue Light
      dark: '#21437a', //Not in figma guide
    },
    alt: {
      main: '#7B7B7B',
    },
    warning: {
      main: '#F3A944', //Low Priority Alarm
    },
    error: {
      main: '#FF5A5A', //High Priority Alarm
    },
    power: {
      main: '#78BB39',
    },
    bess: {
      main: '#57BCDC', //BESS
      light: '#E6F7FD', //BESS Light
    },
    grey: {
      light: '#F5F5F5',
      main: '#E4E4E4',
      dark: '#B1B1B1',
    },
    lightGrey: {
      light: '#F5F5F5',
      main: '#F5F5F5',
      dark: '#F5F5F5',
    },
    genSet: {
      main: '#82C341', //Genset
      light: '#EFF5E9', //Genset Light
    },
    solar: {
      main: '#A6E56A', //Solar
      light: '#ECFFDB', //Solar Light
    },
    utility: {
      main: '#C6C6C6', //Utility
      light: '#EBEBEB', //Utility Light
    },
    background: {
      main: '#F8FAFB', //Backgrounds
    },
    iconography: {
      main: '#B1B1B1', //Iconography
      light: '#CBCBCB', //Iconography
    },
    noAlarm: {
      main: '#EEEEEE', //No Alarm
    },
    axisText: {
      main: '#BEBEBE',
    },
    tableRow: {
      main: '#FAFAFA',
    },
  },
});

theme = createTheme(theme, {
  typography: {
    fontFamily: 'Inter, Roboto, Helvetica, Arial, sans-serif',
    h1: {
      fontSize: '59px',
      fontWeight: 300,
    },
    h2: {
      fontSize: '47px',
      fontWeight: 300,
    },
    h3: {
      [theme.breakpoints.up('xs')]: {
        fontSize: '24px',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: 'clamp(22px, calc(1.375rem + ((1vw - 12.8px) * 1.7188)), 33px)',
      },
      fontWeight: 400,
    },
    h4: {
      [theme.breakpoints.up('xs')]: {
        fontSize: '20px',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: 'clamp(16px, calc(1rem + ((1vw - 12.8px) * 1.25)), 24px)',
      },
      fontWeight: 400,
    },
    h5: {
      [theme.breakpoints.up('xs')]: {
        fontSize: '18px',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: 'clamp(13px, calc(0.8125rem + ((1vw - 12px) * 0.9722)), 20px);',
      },
      fontWeight: 500,
    },
    h5Portal: { fontSize: '20px' },
    subtitle1: {
      fontSize: '16px',
      fontWeight: 400,
    },
    subtitle2: {
      fontSize: '14px',
      fontWeight: 400,
    },
    body1: {
      fontSize: '16px',
      fontWeight: 400,
    },
    body2: {
      fontSize: '14px',
      fontWeight: 400,
    },
    caption: {
      fontSize: '12px',
      fontWeight: 400,
    },
    overline: {
      fontWeight: 500,
      fontSize: '10px',
    },
    overline2: { fontWeight: 100, fontSize: '10px' },
  },
  components: {
    MuiSelect: {
      styleOverrides: {
        root: {
          padding: '0px',
        },
        select: {
          fontStyle: 'normal',
          fontSize: 'small',
        },
        menu: {
          fontStyle: 'normal',
          fontSize: 'small',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontStyle: 'normal',
          fontSize: 'small',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontStyle: 'normal',
          fontSize: 'small',
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          border: '1px white solid',
          color: 'white',
          boxShadow: '1.25px 1.25px 5px rgba(0, 0, 0, 0.12)',
          transform: 'scale(0.9) translate(35%, -35%)',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        containedSecondary: {
          backgroundColor: theme.palette.secondary,
          '&:hover': {
            backgroundColor: theme.palette.secondary.dark,
          },
        },
        sizeLarge: {
          height: '40px',
          paddingLeft: '34px',
          paddingRight: '34px',
        },
        sizeSmall: {
          height: '32px',
          paddingLeft: '14px',
          paddingRight: '14px',
        },
      },
    },
    // removes colors on input element borders
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: boxBorderColor,
        },
        '&:hover $notchedOutline': {
          borderColor: boxBorderColor,
        },
        '&$focused $notchedOutline': {
          borderColor: boxBorderColor,
        },
      },
    },
  },
  classes: {
    box: {
      borderRadius: '4px',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: boxBorderColor,
    },
    card: {
      display: 'flex',
      py: '2.4vw',
    },
    hideMobile: {
      display: 'none',
    },
    passThroughMobile: {
      padding: '0',
      margin: '0',
      width: '100%',
      boxShadow: 'none',
      overflow: 'visible',
      backgroundColor: 'transparent',
      border: 'none',
      borderRadius: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      gap: 0,
    },
    customSelectIcon: {
      position: 'absolute',
      right: '1rem',
      pointerEvents: 'none',
    },
    select: {
      backgroundColor: 'white',
      '&.MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline':
        {
          border: '1px solid',
          borderColor: `${boxBorderColor} !important`,
        },
      '& .MuiSelect-select': {
        py: '5px',
        pr: '3.7rem !important',
        pl: '1.5rem !important',
      },
    },
    selectMobile: {
      width: '100%',
      marginBottom: '5px',
      py: '1rem',
    },
  },
});

theme.recharts = {
  valueColors: ['#85B953', '#325182', '#57BCDC', '#C6C6C6', '#F3A944', '#FF5A5A', '#A6E56A'],
};

const smsTheme = theme;

export default smsTheme;
