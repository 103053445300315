import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';

import { useMobile } from '../../lib/StyleUtil';
import LiveIndicator from '../LiveIndicator';
import ProgressBar from '../ProgressBar';

const Metric = ({
  name,
  value,
  iconUrl,
  mobileIconUrl,
  highlightColor = 'inherit',
  capacityBarBackgroundColor = null,
  unit = '',
  hasLiveData,
  showCapacityPercentageBar = false,
  customStatusText = null,
  compactMode = false,
}) => {
  const theme = useTheme();
  const isMobile = useMobile();

  const hasGoodValue = () => value && value > 0;

  const formatMetric = (val) => {
    const formattedNumber = new Intl.NumberFormat('en-US', {
      maximumFractionDigits: 1,
    }).format(val);
    return formattedNumber;
  };

  const getMetricColor = (highlightColor = '') => {
    if (highlightColor && hasGoodValue()) {
      return highlightColor;
    }
    if (!hasLiveData) {
      return '#D5D5D5';
    }
    return 'inherit';
  };

  const getIndicatorText = () => {
    if (customStatusText) {
      return customStatusText;
    }
    if (hasLiveData) {
      return 'Live';
    }
    return 'Offline';
  };

  const styles = {
    mobileCard: {
      ...theme.classes.box,
      backgroundColor: 'white',
      px: '20px',
      py: '21px',
      boxSizing: 'border-box',
      mb: '8px',
      display: 'flex',
      flexDirection: 'row',
      gap: '13px',
    },
    icon: {
      width: { xs: '90px', md: '4vw', xxl: '76px' },
    },
  };

  const getIndicatorDot = () => (
    <Box sx={{ display: 'inline-block', mx: { xs: '8px', md: '.15rem' } }}>
      <LiveIndicator />
    </Box>
  );

  const renderLiveDataIndicator = () => (
    <>
      <Typography variant="h4" sx={{ display: 'inline-block', marginRight: '.52vw' }}>
        {getIndicatorText()}
      </Typography>
      {!customStatusText && hasLiveData && getIndicatorDot()}
    </>
  );

  return (
    <Box sx={[{ color: '#868686', width: '100%' }, isMobile && styles.mobileCard]}>
      {!(compactMode && showCapacityPercentageBar) && (
        <Box sx={styles.icon}>
          <img
            src={isMobile ? mobileIconUrl : iconUrl}
            alt={`${name} icon`}
            style={{ width: '100%' }}
          ></img>
        </Box>
      )}
      <Box sx={{ flex: 1 }}>
        <Typography variant="h3" data-testid="metric-name" sx={{ mt: '.25vw' }}>
          {name}
        </Typography>

        <Box
          sx={{ color: getMetricColor(highlightColor), whiteSpace: 'nowrap' }}
          data-testid="metric-value"
        >
          <Typography
            variant="h2"
            sx={{
              fontSize: {
                xs: '40px',
                md: 'clamp(36px, calc(2.25rem + ((1vw - 12px) * 2.5)), 54px)',
              },
              fontWeight: '500',
              lineHeight: '1',
              display: 'inline',
            }}
          >
            {hasLiveData ? formatMetric(value) : '-- '}
          </Typography>
          <Typography variant="h3" sx={{ display: 'inline-block' }}>
            {unit}
          </Typography>
          {compactMode && hasLiveData && (
            <Box
              sx={{
                display: 'inline-block',
                position: 'relative',
                marginLeft: '1vw',
                bottom: '3px',
              }}
            >
              {getIndicatorDot()}
            </Box>
          )}
        </Box>

        <Box sx={{ color: getMetricColor() }} data-testid="metric-live-indicator">
          {!showCapacityPercentageBar && !compactMode && renderLiveDataIndicator()}
        </Box>
        {showCapacityPercentageBar && (
          <Box sx={{ mt: '1.4vw' }}>
            <ProgressBar
              variant="determinate"
              value={value || 0}
              colorPrimary={highlightColor}
              colorBackground={hasLiveData ? capacityBarBackgroundColor : 'rgba(213, 213, 213, .1)'}
            ></ProgressBar>
          </Box>
        )}
      </Box>
    </Box>
  );
};

Metric.propTypes = {
  name: PropTypes.string,
  value: PropTypes.number,
  highlightColor: PropTypes.string,
  capacityBarBackgroundColor: PropTypes.string,
  iconUrl: PropTypes.string,
  mobileIconUrl: PropTypes.string,
  unit: PropTypes.string,
  hasLiveData: PropTypes.bool,
  showCapacityPercentageBar: PropTypes.bool,
  customStatusText: PropTypes.string,
  compactMode: PropTypes.bool,
};

export default Metric;
