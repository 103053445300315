import './index.css';

import { withLDProvider } from 'launchdarkly-react-client-sdk';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import Auth0ProviderWithNavigate from './components/Auth/Auth0ProviderWithNavigate';
import reportWebVitals from './reportWebVitals';

const LDProvider = withLDProvider({
  clientSideID: `${process.env.REACT_APP_LAUNCHDARKLY_CLIENT_SIDE_ID}`,
  context: {
    kind: 'user',
    key: 'defaultUser',
    name: 'defaultUser',
    email: 'defaultUser@scalemicrogrids.com',
  },
})(App);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Auth0ProviderWithNavigate>
        <LDProvider />
      </Auth0ProviderWithNavigate>
    </BrowserRouter>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
