import { create } from 'zustand';
import { getUserProfile, putUser } from './../lib/Queries';

export type User = {
  name?: string | null;
  email_address?: string | null;
  rationale?: string | null;
  global_notifications?: boolean | null;
  roles?: string[] | null;
  customer?: string | null;
  terms_agreement?: boolean | null;
  exp: number | null;
  picture?: string | null;
} | null;

type State = {
  user: User | null;
};

const initialState: State = {
  user: null,
};

const logoutHelper = (set: any) => {
  set({
    user: null,
  });
};

const isAdminHelper = (get: any) => {
  const user = get().user;
  return user?.roles?.includes('operate:admin');
};

const setUserHelper = async (set: any, get: any, authData: any) => {
  /*
   NTM - Removing the "loggedIn" flag here.  We're only going to make this 
   call if we have user auth data.  If for some reason it gets called when
   we don't have a valid auth cookie that's OK!  We might call this
   whenever we want to refresh the user object in the front end from back end 
   data, so get rid of the "already exists" check.  Also changed this to an 
   explicit promise then / catch structure.  If the API call fails, we need
   to set the local user to null, not just exit the function due to error.
   */
  try {
    const response = await getUserProfile();
    const updatedUser = { ...get().user, ...response };
    // if we have anything from the auth response that we want
    // to copy over to the user object, we can do that here.
    updatedUser.picture = authData?.picture;
    updatedUser.exp = authData?.exp;
    set({ user: updatedUser });
  } catch (err) {
    // NTM - in case of an error with the getUserProfile call, we'll just set
    // the user to a falsy value (null, in this case, since we don't know)
    set({ user: null });
  }
};

const setTermsAgreementHelper = async (set: any, get: any) => {
  await putUser({ terms_agreement: true });
  const updatedUser = { ...get().user, terms_agreement: true };

  set({
    user: updatedUser,
  });
};

type Actions = {
  setUser: (authData: object) => Promise<void>;
  logout: () => void;
  reset: () => void;
  isAdmin: () => boolean;
  setTermsAgreement: () => Promise<void>;
};

const useUserStore = create<State & Actions>()((set, get) => ({
  ...initialState,
  setUser: async (authData) => setUserHelper(set, get, authData),
  logout: () => logoutHelper(set),
  isAdmin: () => isAdminHelper(get),
  reset: () => {
    set(initialState);
  },
  setTermsAgreement: () => setTermsAgreementHelper(set, get),
}));

export default useUserStore;
