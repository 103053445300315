import { Box, Divider, MenuItem, Select, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';
import { useMobile } from '../lib/StyleUtil';
import useStore from '../state/KioskStore';
import useUserStore from '../state/UserStore';

const ModeSelect = () => {
  const theme = useTheme();
  const isMobile = useMobile();
  const store = useStore((state) => state);
  const [searchParams, setSearchParams] = useSearchParams();
  const userStore = useUserStore((state) => state);
  const { logout: authLogout } = useAuth0();

  const setKioskMode = () => {
    store.reset();
    store.setMode('kiosk');

    searchParams.delete('site');
    searchParams.delete('time-range');
    searchParams.set('mode', 'kiosk');
    setSearchParams(searchParams);
  };

  const handleChange = (event) => {
    const value = event.target.value;
    if (value === 'kiosk') {
      setKioskMode();
      return;
    }
    if (value === 'logout') {
      authLogout({ logoutParams: { returnTo: window.location.origin } });
      return;
    }
    store.setSiteUuid(event.target.value);
  };

  const getMenuItem = (value, displayText) => {
    const selected = value === store.siteUuid;
    return (
      <MenuItem
        value={value}
        sx={{
          '&.Mui-selected': { backgroundColor: 'rgba(217, 217, 217, 0.1) !important' },
          px: '1.7rem ',
        }}
        key={value}
      >
        <Typography
          variant="h5"
          sx={{ color: selected ? theme.palette.secondary.main : '#6B6B6B' }}
        >
          {displayText}
        </Typography>
        {selected && (
          <Box sx={{ ml: '.75rem' }}>
            <img src={'/icons/select-checkmark-icon.svg'} alt={'select checkmark icon'} />
          </Box>
        )}
      </MenuItem>
    );
  };

  const getSiteNameTruncated = (siteUuid, length = 20) => {
    const site = store.customerSites.find((s) => s.uuid === siteUuid);
    const siteNameRaw = site ? site.name : '';

    if (!isMobile) return siteNameRaw;

    return siteNameRaw.length > length ? `${siteNameRaw.substring(0, length)}...` : siteNameRaw;
  };

  const customIcon = ({ className }) => {
    const isOpen = className.includes('MuiSelect-iconOpen');

    return (
      <Box sx={[theme.classes.customSelectIcon, { right: '1.3rem' }]}>
        <img
          src={isOpen ? '/icons/select-close-icon.svg' : '/icons/select-open-icon.svg'}
          alt={isOpen ? 'open mode select box icon' : 'close mode select icon'}
        />
      </Box>
    );
  };

  customIcon.propTypes = { className: PropTypes.string };

  if (!store.customerSites || !store.siteUuid) return <></>;
  return (
    <Select
      value={store.siteUuid}
      onChange={handleChange}
      sx={[theme.classes.select, isMobile && theme.classes.selectMobile]}
      renderValue={(selected) => (
        <Typography variant="h3" sx={{ color: theme.palette.secondary.main }}>
          {getSiteNameTruncated(selected)}
        </Typography>
      )}
      IconComponent={customIcon}
      MenuProps={{
        PaperProps: {
          sx: {
            py: '.8rem',
          },
        },
      }}
      data-testid="mode-select"
    >
      {store.customerSites.map((item) => getMenuItem(item.uuid, item.name))}

      {/* using two isMobile because fragment brakes event listener */}
      {!isMobile && <Divider sx={{ mx: '1rem', my: '.8rem !important' }} />}
      {!isMobile && getMenuItem('kiosk', 'Kiosk View')}
      {userStore.user && !isMobile
        ? [
            <Divider key="divider" sx={{ mx: '1rem', my: '.8rem !important' }} />,
            getMenuItem('logout', 'Log Out'),
          ]
        : null}
    </Select>
  );
};

export default ModeSelect;
