import PropTypes from 'prop-types';
import React from 'react';
import { ComposedChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';

import smsTheme from '../../../../style/theme';
import GraphUtil from './../../lib/GraphUtil';
const AlarmTimeline = ({ timeSeries, displayRange, position }) => {
  return (
    <ResponsiveContainer width="99%" height="7%">
      <ComposedChart data={timeSeries}>
        <YAxis axisLine={false} width={position.yAxisWidth} domain={[0, 100]} hide={true} />
        <XAxis
          dataKey="bucket"
          padding={position.xAxisPadding}
          tickLine={false}
          axisLine={false}
          domain={['dataMin', 'dataMax']}
          tickFormatter={GraphUtil.getTickFormatter(displayRange)}
          interval={GraphUtil.calculateTickInterval(displayRange.range)}
          stroke={smsTheme.palette.axisText.main}
          style={{
            fontFamily: 'Inter, Arial, Helvetica, sans-serif',
            fontStyle: 'normal',
            fontWeight: '500',
            fontSize: '8px',
            height: '50px',
          }}
          xAxisId={0}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

AlarmTimeline.propTypes = {
  timeSeries: PropTypes.array,
  displayRange: PropTypes.object,
  bigBuckets: PropTypes.array,
  position: PropTypes.object,
};
export default AlarmTimeline;
