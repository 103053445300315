import axios from 'axios';

import { baseUrlPresentation } from './Queries';

const shouldIgnoreLogout = (url) => {
  return (
    url.includes('user-auth-pwd') ||
    url.includes('public') ||
    url.includes('user') ||
    url.includes('logout')
  );
};

const isScaleDomain = (url) => {
  return url.includes(baseUrlPresentation);
};

export const registerRequestInterceptor = async (getAccessTokenSilently) => {
  const requestAuthInterceptor = async (config) => {
    if (!isScaleDomain(config.url)) {
      return config;
    }

    try {
      const credential = await getAccessTokenSilently();

      config.headers = {
        Authorization: 'Bearer ' + credential,
        'content-type': 'application/json',
      };
    } catch (error) {
      // request for credential failed so continue without one
    }
    // return scale domain request with or without credential
    return config;
  };

  axios.interceptors.request.use(requestAuthInterceptor, (req) => req);
};

export const registerResponseInterceptor = (authLogout) => {
  const responseErrorInterceptor = (error) => {
    // check the request error code. If it is an auth problem, redirect to the login page.

    if (
      !shouldIgnoreLogout(error.config.url) &&
      (error.response.status === 401 || error.response.status === 403)
    ) {
      authLogout({ logoutParams: { returnTo: window.location.origin } });
    }
    return Promise.reject(new Error(error));
  };

  axios.interceptors.response.use((res) => res, responseErrorInterceptor);
};
