import { Box, CircularProgress, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';

import { getLocalFormattedDate, getLocalFormattedTime } from '../../lib/DateUtil';

const SiteAlarms = ({ alarms, timezone, loading = false }) => {
  const theme = useTheme();
  const getAlarm = (alarm) => {
    return (
      <Box sx={styles.alarm} key={alarm.name}>
        <Typography variant="body2" sx={{ flexBasis: '50%', color: 'black' }}>
          {alarm.name}
        </Typography>
        <Typography variant="body2" sx={[{ flexBasis: '20%' }, styles.secondaryText]}>
          {alarm.type}
        </Typography>
        <Box sx={{ display: 'flex ' }}>
          <Typography variant="body2" sx={[styles.secondaryText, { mr: '.8rem' }]}>
            {getLocalFormattedDate(alarm.date, timezone)}
          </Typography>
          <Typography variant="body2" sx={[styles.secondaryText]}>
            {getLocalFormattedTime(alarm.date, timezone)}
          </Typography>
        </Box>
      </Box>
    );
  };

  const getNoAlarms = () => {
    return (
      <Box sx={[styles.alarm, { justifyContent: 'center' }]}>
        <Typography variant="body2" sx={[styles.secondaryText]}>
          No Events
        </Typography>
      </Box>
    );
  };

  const getLoading = () => {
    return (
      <Box sx={[styles.alarm, { justifyContent: 'center' }]}>
        <CircularProgress />
      </Box>
    );
  };

  const styles = {
    mainContainer: {
      width: '100%',
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
    },
    alarm: {
      display: 'flex',
      justifyContent: 'space-between',
      backgroundColor: theme.palette.tableRow.main,
      px: '20px',
      py: '10px',
    },

    secondaryText: {
      color: '#7C7C7C',
    },
  };

  if (!timezone) {
    return <></>;
  }
  if (loading) {
    return getLoading();
  }

  return (
    <Box sx={styles.mainContainer}>
      {!alarms?.length ? getNoAlarms() : alarms.map((alarm) => getAlarm(alarm))}
    </Box>
  );
};

SiteAlarms.propTypes = {
  alarms: PropTypes.array,
  timezone: PropTypes.string,
  loading: PropTypes.bool,
};

export default SiteAlarms;
