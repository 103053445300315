import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

export const getNow = (targetTimezone) => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const currentTime = dayjs().tz(targetTimezone);
  return currentTime;
};

export const getHoursAgo = (hours, targetTimezone) => {
  const currentTime = getNow(targetTimezone);
  const hoursAgo = currentTime.subtract(hours, 'hour');
  return hoursAgo;
};

export const timeRanges = {
  lastDay: {
    getRangeStart: (targetTimezone) => {
      return getHoursAgo(24, targetTimezone).format();
    },
    getRangeStop: (targetTimezone) => {
      return getNow(targetTimezone).format();
    },
    interval_seconds: 120,
  },
  lastWeek: {
    getRangeStart: (targetTimezone) => {
      return getHoursAgo(168, targetTimezone).format();
    },
    getRangeStop: (targetTimezone) => {
      return getNow(targetTimezone).format();
    },
    interval_seconds: 840,
  },
  todaySoFar: {
    getRangeStart: (targetTimezone) => {
      return getNow(targetTimezone).startOf('day').format();
    },
    getRangeStop: (targetTimezone) => {
      return getNow(targetTimezone).format();
    },
    interval_seconds: 120,
  },
  last18Minutes: {
    getRangeStart: (targetTimezone) => {
      return getHoursAgo(18 / 60, targetTimezone).format();
    },
    getRangeStop: (targetTimezone) => {
      return getNow(targetTimezone).format();
    },
    interval_seconds: 120,
  },
};

export const timeStringDifferenceHours = (startTimeString, stopTimeString) => {
  const startTime = dayjs(startTimeString);
  const stopTime = dayjs(stopTimeString);
  const duration = stopTime.diff(startTime, 'second') / 3600;
  return duration;
};

export const getLocalTime = (gmtTime, localTimezone) => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const localTime = dayjs.utc(gmtTime).tz(localTimezone).format();
  return localTime;
};

export const getLocalFormattedDate = (dateString, localTimezone) => {
  const localTime = getLocalTime(dateString, localTimezone);
  const formattedDate = dayjs(localTime).tz(localTimezone).format('MM/DD/YYYY');
  return formattedDate;
};

export const getLocalFormattedTime = (dateString, localTimezone) => {
  const localTime = getLocalTime(dateString, localTimezone);
  const formattedTime = dayjs(localTime).tz(localTimezone).format('HH:mm:ss');
  return formattedTime;
};

export const addUnixTime = (timeSeriesData) => {
  return timeSeriesData.map((row) => {
    let newRow = { ...row };
    newRow.unixTimestamp = new Date(newRow.bucket).getTime();
    return newRow;
  });
};

export const getTimesBetween = (start, end, timeUnit, increment) => {
  const startTime = dayjs(start);
  const endTime = dayjs(end);

  let currentTime = startTime.startOf(timeUnit);

  const times = [];

  while (currentTime.isBefore(endTime)) {
    const currentBaseUnit = timeUnit === 'hour' ? currentTime.hour() : currentTime.day();

    // only add time if it is a multiple of the increment and within the range
    if (currentBaseUnit % increment === 0 && currentTime.isAfter(startTime)) {
      times.push(currentTime.toDate());
    }

    currentTime = currentTime.add(1, timeUnit);
  }

  // remove times close to the end to prevent text overflow
  const lastTime = dayjs(times[times.length - 1]);
  if (timeUnit === 'hour' && endTime.diff(lastTime, 'hour', true) < 1) {
    times.pop();
  }
  if (timeUnit === 'day' && endTime.diff(lastTime, 'day', true) < 0.25) {
    times.pop();
  }

  return times;
};
