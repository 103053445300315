import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { formatEnergyKwh, getEnergyAllKwh } from '../../lib/DataUtil';
import { getLocalFormattedDate } from './../../lib/DateUtil';
const GenerationTable = ({ displayRange, timeSeries, loading }) => {
  const theme = useTheme();
  const [energyAllAssets, setEnergyAllAssets] = useState(null);

  const updateEnergyTotals = async (timeSeries) => {
    if (!timeSeries) return;

    const newEnergyAllKwh = await getEnergyAllKwh(timeSeries.time_series);
    setEnergyAllAssets(newEnergyAllKwh);
  };

  useEffect(() => {
    updateEnergyTotals(timeSeries);
  }, [timeSeries]);

  const getAssetName = (name) => {
    return name.replace('_', ' ');
  };

  const styles = {
    footerRow: {
      background: '#FAFAFA',
      '& > td': {
        borderTop: 'none !important',
        borderBottom: 'none !important',
        py: '8px !important',
      },
    },
    bodyRow: {
      '&:last-child td': {
        borderBottom: 'none',
      },
    },
    row: {
      td: {
        color: '#6C6C6C',
        py: '2px',
      },
      '& > :last-child': {
        color: theme.palette.genSet.main,
        textAlign: 'right',
      },
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      flexWrap: 'nowrap',
    },
  };

  const dateOptions = {
    today: 'Today',
    yesterday: 'Yesterday',
    '7days': 'Last 7 Days',
    '28days': 'Last 28 Days',
    '90days': 'Last 90 Days',
  };

  const getTimeRangeText = () => {
    if (!displayRange) return '';
    if (displayRange.range !== 'custom') {
      return dateOptions[displayRange.range];
    }
    const start = getLocalFormattedDate(displayRange.start, displayRange.timezone);
    const stop = getLocalFormattedDate(displayRange.stop, displayRange.timezone);
    return `${start} - ${stop}`;
  };

  const getCell = (value) => (
    <TableCell>
      <Typography variant="caption">{getAssetName(value)}</Typography>
    </TableCell>
  );

  const getLoading = () => (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
      }}
    >
      <CircularProgress />
    </Box>
  );

  return (
    <>
      <Box sx={styles.header}>
        <Typography variant="subtitle2">Generation</Typography>
        <Typography variant="caption" sx={{ color: '#949494' }}>
          {getTimeRangeText()}
        </Typography>
      </Box>
      {loading || !timeSeries || !energyAllAssets ? (
        getLoading()
      ) : (
        <Box sx={{ mt: '12px', px: '10px' }}>
          <TableContainer>
            <Table>
              <TableBody>
                {energyAllAssets.assets.map((asset) => (
                  <TableRow key={asset.name} sx={[styles.bodyRow, styles.row]}>
                    {getCell(getAssetName(asset.name))}
                    {getCell(formatEnergyKwh(asset.energyKwh))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TableContainer sx={[styles.table, { mt: '5px' }]}>
            <Table>
              <TableBody>
                <TableRow sx={[styles.footerRow, styles.row]}>
                  {getCell(getAssetName('Total Generation'))}
                  {getCell(formatEnergyKwh(energyAllAssets.totalKwh))}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </>
  );
};

GenerationTable.propTypes = {
  displayRange: PropTypes.object,
  timeSeries: PropTypes.object,
  loading: PropTypes.bool,
};

export default GenerationTable;
