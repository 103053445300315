import { Box, MenuItem, Select, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';

const TitleSelect = ({ options, selectedValue, setOption }) => {
  const theme = useTheme();

  const handleChange = (event) => {
    setOption(event.target.value);
  };

  const getMenuItem = (value, displayText) => {
    const selected = value === selectedValue;
    return (
      <MenuItem
        value={value}
        sx={{
          '&.Mui-selected': { backgroundColor: 'rgba(217, 217, 217, 0.1) !important' },
          px: '1.7rem ',
        }}
        key={value}
      >
        <Typography
          variant="h5"
          sx={{ color: selected ? theme.palette.secondary.main : '#6B6B6B' }}
        >
          {displayText}
        </Typography>
        {selected && (
          <Box sx={{ ml: '.75rem' }}>
            <img src={'/icons/select-checkmark-icon.svg'} alt={'select checkmark icon'} />
          </Box>
        )}
      </MenuItem>
    );
  };

  const customIcon = ({ className }) => {
    const isOpen = className.includes('MuiSelect-iconOpen');

    return (
      <Box sx={[theme.classes.customSelectIcon, { right: '1.3rem' }]}>
        <img
          src={isOpen ? '/icons/select-close-icon.svg' : '/icons/select-open-icon.svg'}
          alt={isOpen ? 'open title select box icon' : 'close title select icon'}
        />
      </Box>
    );
  };

  const getDisplayValue = (siteUuid, length = 20) => {
    const option = options.find((s) => s.value === selectedValue);
    const optionName = option ? option.name : '';
    return optionName;
  };

  customIcon.propTypes = { className: PropTypes.string };

  if (!options || !selectedValue) return <></>;
  return (
    <Select
      value={selectedValue}
      onChange={handleChange}
      sx={theme.classes.select}
      renderValue={(selected) => (
        <Typography variant="h3" sx={{ color: theme.palette.secondary.main }}>
          {getDisplayValue(selected)}
        </Typography>
      )}
      IconComponent={customIcon}
      MenuProps={{
        PaperProps: {
          sx: {
            py: '.8rem',
          },
        },
        anchorOrigin: {
          vertical: 'bottom',
        },
        transformOrigin: {
          horizontal: 'left',
        },
      }}
      data-testid="title-select"
    >
      {options.map((item) => getMenuItem(item.value, item.name))}
    </Select>
  );
};

TitleSelect.propTypes = {
  options: PropTypes.array,
  selectedValue: PropTypes.string,
  setOption: PropTypes.func,
};

export default TitleSelect;
