import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import AgreementDialog from './../AgreementDialog/AgreementDialog';
import { privacyText, privacyTitle, termsText, termsTitle } from './../AgreementPage/AgreementText';

export const getInitials = (name) => {
  if (!name) return '';
  const nameWords = name.split(' ');
  if (nameWords.length > 1) {
    return nameWords[0].charAt(0) + nameWords[1].charAt(0);
  }
  return nameWords[0].charAt(0);
};

const Topbar = ({ userName }) => {
  const { logout: authLogout } = useAuth0();
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [showTermsDialog, setShowTermsDialog] = useState(false);
  const [showPrivacyDialog, setShowPrivacyDialog] = useState(false);
  const theme = useTheme();

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const styles = {
    toolbar: {
      bgcolor: 'white',
      boxShadow: 'none',
      px: '1rem',
      display: 'flex',
      justifyContent: 'space-between',
    },
    appbar: { bgcolor: 'transparent', boxShadow: 'none' },
    menu: {
      mt: '45px',
      right: '30px',
      '.MuiMenu-paper': {
        border: '1px solid #e1e2e4',
        borderRadius: '5px',
        width: '11rem',
      },
    },
  };

  const getMenuItem = (key, text, onClick) => {
    return (
      <MenuItem key={key} onClick={onClick}>
        <Typography
          className="li-text"
          textAlign="center"
          sx={{ color: '#686868', fontWeight: '300' }}
        >
          {text}
        </Typography>
      </MenuItem>
    );
  };

  return (
    <>
      <AppBar position="sticky" sx={styles.appbar}>
        <Toolbar disableGutters sx={styles.toolbar}>
          <Box sx={{ width: '136px', ml: '10px', display: 'flex' }}>
            <img src="/scale-logo.svg" alt="scale microgrids logo" style={{ width: '100%' }} />
          </Box>
          <Box sx={{ flexGrow: 0, top: '10px !important' }}>
            <Box onClick={handleOpenUserMenu}>
              <IconButton>
                <Avatar
                  alt={userName}
                  data-testid="avatar"
                  sx={{ bgcolor: theme.palette.secondary.main }}
                >
                  {getInitials(userName)}
                </Avatar>
              </IconButton>
              <KeyboardArrowDownIcon
                sx={{
                  zIindex: 9999,
                  color: '#6C6C6C',
                  cursor: 'pointer',
                  position: 'relative',
                  top: '7px',
                }}
              />
            </Box>
            <Menu
              sx={styles.menu}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
              slotProps={{
                sx: {
                  boxShadow: 'none',
                },
              }}
            >
              {getMenuItem('logout', 'Log Out', () =>
                authLogout({ logoutParams: { returnTo: window.location.origin } }),
              )}
              {getMenuItem('privacy-policy', 'Privacy Policy', () => setShowPrivacyDialog(true))}
              {getMenuItem('terms', 'Terms & Conditions', () => setShowTermsDialog(true))}
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <AgreementDialog
        show={showPrivacyDialog}
        bodyMarkdown={privacyText}
        title={privacyTitle}
        interactive={false}
        onClose={() => setShowPrivacyDialog(false)}
      />
      <AgreementDialog
        show={showTermsDialog}
        bodyMarkdown={termsText}
        title={termsTitle}
        interactive={false}
        onClose={() => setShowTermsDialog(false)}
      />
    </>
  );
};

Topbar.propTypes = {
  userName: PropTypes.string,
};

export default Topbar;
