import './App.css';

import { useAuth0 } from '@auth0/auth0-react';
import { Box, ThemeProvider } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AgreementPage from './components/AgreementPage/AgreementPage';
import CallbackPage from './components/Auth/CallbackPage';
import Login from './components/Auth/Login';
import NotAuthorized from './components/Auth/NotAuthorized';
import ErrorBoundary from './components/ErrorBoundary';
import Kiosk from './components/Kiosk/Kiosk';
import Portal from './components/Portal/Portal';
import { registerRequestInterceptor, registerResponseInterceptor } from './lib/QueryInterceptors';
import { addSegment } from './lib/SegmentUtil';
import useStore from './state/KioskStore';
import useUserStore from './state/UserStore';
import smsTheme from './style/theme';
import ErrorCallout from './components/ErrorCallout';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
    },
  },
});

function App() {
  const {
    isLoading: isAuthLoading,
    isAuthenticated,
    getAccessTokenSilently,
    user: authUser,
    logout: authLogout,
    getIdTokenClaims,
    error: authError,
    loginWithRedirect,
  } = useAuth0();
  const userStore = useUserStore((state) => state);
  const kioskStore = useStore((state) => state);

  const [isInterceptorSetup, setIsInterceptorSetup] = useState(false);

  const registerInterceptors = async () => {
    registerRequestInterceptor(getAccessTokenSilently);
    registerResponseInterceptor(authLogout);
    setIsInterceptorSetup(true);
  };

  useEffect(() => {
    registerInterceptors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    addSegment();
  }, []);

  const identifyUserInSegment = () => {
    if (userStore.user) {
      // Now that we have a valid user, we can identify them in Segment
      if (window.analytics) {
        window.analytics.identify(userStore.user.email_address, {
          name: userStore.user.name,
          app: 'Customer Portal',
        });
      }
    }
  };

  useEffect(() => {
    identifyUserInSegment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userStore.user]);

  const setUser = async () => {
    if (isInterceptorSetup && authUser) {
      const claims = await getIdTokenClaims();
      await userStore.setUser(claims);
    }
  };

  useEffect(() => {
    setUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInterceptorSetup, authUser]);

  // Used by kiosk requests to login and redirect back to kiosk when resources fail to load.  This is necessary since the kiosk has a public and private mode, so we cannot directly know whether the redirection is needed until resources fail.
  const setLogin = () => {
    kioskStore.setLogin((redirectUri) => {
      loginWithRedirect({ appState: { returnTo: '/kiosk' } });
    });
  };

  useEffect(() => {
    setLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logoutIfExpired = () => {
    if (userStore?.user?.auto_logout_disabled) {
      return;
    }

    let timeLeft = userStore.user?.exp - Math.floor(Date.now() / 1000);
    // log the user out if we can't calculate the token expiry
    // or it has passed.
    if (!isNaN(timeLeft) && timeLeft <= 0) {
      userStore.logout();
      authLogout({ logoutParams: { returnTo: window.location.origin } });
    }
  };

  useEffect(() => {
    setInterval(logoutIfExpired, 15000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAuthError = () => {
    if (!authError) {
      return;
    }
    const redirectLocation = window.location.origin + '/not-authorized';
    authLogout({ logoutParams: { returnTo: redirectLocation } });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleAuthError, [authError]);

  const getKiosk = () => <Kiosk key={kioskStore.mode} mode={kioskStore.mode} />;

  const getLoggedInRoutes = () => (
    <Routes>
      <Route exact path="/" element={<Portal />} />
      <Route path="/kiosk" element={getKiosk()} />
      <Route path="/login" element={<Login />} />
      <Route path="/not-authorized" element={<NotAuthorized />} />
      <Route path="*" element={<Navigate to={'/'} />} />
    </Routes>
  );

  const getLoggedOutRoutes = () => (
    <Routes>
      <Route path="/callback" element={<CallbackPage />} />
      <Route path="/login" element={<Login />} />
      <Route path="/kiosk" element={getKiosk()} />
      <Route path="/not-authorized" element={<NotAuthorized />} />
      <Route path="*" element={<Login />} />
    </Routes>
  );

  const agreementRoutes = () => (
    <Routes>
      <Route path="*" element={<AgreementPage />} />
    </Routes>
  );

  const getRoutes = () => {
    // normal logged in
    if (isAuthenticated) {
      if (userStore.user === null) {
        // not ready to render routes yet
        return null;
      }
      if (userStore.user?.terms_agreement) {
        return getLoggedInRoutes();
      }
      // needs terms agreement
      if (!userStore.user?.terms_agreement) {
        return agreementRoutes();
      }
    }
    return getLoggedOutRoutes();
  };

  if (isAuthLoading) {
    return null;
  }

  if (kioskStore.hasError)
    return (
      <Box
        sx={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ErrorCallout showRetry={false} />
      </Box>
    );

  return (
    <ThemeProvider theme={smsTheme}>
      <ErrorBoundary>
        <QueryClientProvider client={queryClient}>
          <Box component="main">{getRoutes()}</Box>
        </QueryClientProvider>
      </ErrorBoundary>
    </ThemeProvider>
  );
}

export default App;
