import { Box, Divider, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import Clock from 'react-live-clock';

const SiteHeader = ({ metadata }) => {
  if (!metadata) return <></>;
  return (
    <Box width="100%" display="flex" flexDirection="row" justifyContent="space-between">
      <Box display="flex" flexDirection="row">
        {metadata.address ? (
          <>
            <Box>
              <Typography variant="caption" color="secondary">
                {metadata.address.address1}, {metadata.address.city}, {metadata.address.state}{' '}
                {metadata.address.zip}
              </Typography>
            </Box>
            <Divider orientation="vertical" flexItem sx={{ mx: '1rem' }} />
          </>
        ) : null}
        {metadata.latitude && metadata.longitude ? (
          <Box>
            <a
              href={
                'https://www.google.com/maps/search/?api=1&query=' +
                metadata.latitude +
                ',' +
                metadata.longitude
              }
              target="_new"
              style={{ textDecoration: 'none' }}
            >
              <Typography variant="caption" color="secondary">
                {metadata.latitude},{metadata.longitude}
              </Typography>
            </a>
          </Box>
        ) : null}
      </Box>
      <Box display="flex" flexDirection="row">
        <Box mr="0.25em">
          <Typography variant="caption" color="grey">
            Time at site:
          </Typography>
        </Box>
        <Box mr="1em">
          <Typography variant="caption" color="secondary">
            <Clock format={'HH:mm:ss'} ticking={true} timezone={metadata.timezone} />
          </Typography>
        </Box>
        <Box>
          <Typography variant="caption" color="secondary">
            {metadata.timezone?.replaceAll('_', ' ')}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

SiteHeader.propTypes = {
  metadata: PropTypes.object,
};

export default SiteHeader;
