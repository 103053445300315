import Box from '@mui/material/Box';
import { keyframes } from '@mui/system';
import React from 'react';

const pulseRing = keyframes`
  0% {
    transform: scale(0.33);
  }
  80%, 100% {
    opacity: 0;
  }
`;

const styles = {
  liveIndicator: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '8px',
    height: '8px',
    '&::before': {
      content: '""',
      position: 'relative',
      display: 'block',
      width: '300%',
      height: '300%',
      marginLeft: '-100%',
      marginTop: '-100%',
      borderRadius: '45px',
      backgroundColor: '#A6E56A',
      animation: `${pulseRing} 2.5s cubic-bezier(0.215, 0.61, 0.355, 1) infinite`,
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      display: 'block',
      width: '100%',
      height: '100%',
      backgroundColor: '#A6E56A',
      borderRadius: '7px',
    },
  },
};

function LiveIndicator() {
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'inline-block',
        top: { xl: '-6.5px', xs: '-5px' },
      }}
    >
      <Box sx={styles.liveIndicator}></Box>
    </Box>
  );
}

export default LiveIndicator;
