import { Alert, Box, Typography } from '@mui/material';
import React from 'react';

const DataComponentError = () => {
  return (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: '200px' }}
    >
      <Alert className={`banner banner-${'warning'}`} severity="warning">
        Error loading data
      </Alert>
      <Typography variant="caption">
        Something went wrong and we were unable to load the requested data
      </Typography>
    </Box>
  );
};

export default DataComponentError;
