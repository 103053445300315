import { Box, LinearProgress, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Handle, Position, ReactFlowProvider } from 'reactflow';

import { assemblyComponent, loadChilds } from '../Logic/FormatUtils';
import PieChartNode from './PieChartNode';

const CustomNode = memo(({ data }) => {
  const theme = useTheme();

  /* DER color rules:
        if zero power, text color is dark grey
        if positive power, text color is power color
        if negative power, text color is BESS color
    */
  const getDERColor = (power) => {
    if (power === null) return theme.palette.grey.light;
    if (power > 0) return theme.palette.power.main;
    if (power < 0) return theme.palette.bess.main;
    if (power === 0) return theme.palette.grey.dark;
  };

  const getPowerColor = (power) => {
    if (power === null) return theme.palette.error.main;
    if (power > 0) return theme.palette.power.main;
    if (power < 0) return theme.palette.bess.main;
    if (power === 0) return theme.palette.grey.dark;
  };

  const getPercentageDisplay = (data) => {
    let pctDisplay = '--';
    let pctVal = Math.round(data?.source.toLowerCase() === 'bess' ? data.soc : data.percentage);
    if (!isNaN(pctVal)) {
      pctDisplay = pctVal + '%';
    }
    return pctDisplay;
  };

  let isChild = false;
  let bgColor = '#85B953';
  const AssemblyComponent = assemblyComponent[data?.source.replace(/ \d+$/, '').toLowerCase()];
  if (data.nodeType === 'square') {
    isChild = loadChilds.includes(data.source.replace(/ \d+$/, ''));
    bgColor =
      data?.power > 0
        ? theme.palette.power.main
        : data?.power < 0
          ? theme.palette.bess.main
          : theme.palette.grey.main;
  }

  const derColor = getDERColor(data.power);
  const powerColor = getPowerColor(data.power);
  const nameColor = data.power === null ? theme.palette.grey.main : theme.palette.grey.dark;
  const barColor = data.power === null ? 'lightGrey' : 'grey';
  const loadPX = data?.source.toLowerCase() === 'load' ? '2' : '1';
  const borderNode = `${loadPX}px solid ${nameColor}`;

  return (
    <div
      className={data.source !== 'utility-pie' && data.source !== 'load-pie' ? 'custom-node' : ''}
      style={{
        '--border-custom-node': borderNode,
        '--custom-left': isChild ? '9px' : '0px',
        background: '#fff',
        boxShadow:
          data.source !== 'utility-pie' && data.source !== 'load-pie'
            ? '3px 3px 10px rgba(0, 0, 0, 0.1)'
            : null,
      }}
    >
      <ReactFlowProvider>
        <Handle type="target" position={Position.Left} id="left" style={{ background: '#fff' }} />
        {data.source === 'utility-pie' || data.source === 'load-pie' ? (
          <PieChartNode value={data.percentage} />
        ) : (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems={data?.source === 'Load' || data.source === 'Utility' ? 'center' : 'left'}
            height="100%"
          >
            {isChild && (
              <AssemblyComponent
                className="assembly-icon"
                style={{
                  '--assembly-icon-color': derColor,
                }}
              />
            )}
            <Box className="node-source" style={{ '--node-source-color': nameColor }}>
              {data?.source}
            </Box>
            <Box className="node-data" style={{ textAlign: 'left' }}>
              <span style={{ color: powerColor }}>
                {data.power === null ? 'Error' : `${Math.round(data?.power)}kW`}
              </span>
            </Box>
            {isChild && (
              <div style={{ position: 'relative' }}>
                <LinearProgress
                  color={barColor}
                  value={(data?.source.toLowerCase() === 'bess' ? data.soc : data.percentage) || 0}
                  sx={{
                    height: '12px',
                    '--bs-progress-bar-bg':
                      data?.source.toLowerCase() === 'bess' ? '#57BCDC' : bgColor,
                    '--bs-progress-bg': '#fff',
                    '--node-source-color': powerColor,
                  }}
                  variant="determinate"
                />
                <Typography
                  sx={{
                    position: 'absolute',
                    color: nameColor,
                    top: 0,
                    left: '5%',
                    transform: 'translate(-10%, -11%)',
                  }}
                  variant="caption"
                >
                  {data?.source.toLowerCase() === 'bess' ? 'SOC:' : 'CF:'}
                </Typography>
                <Typography
                  sx={{
                    position: 'absolute',
                    color: derColor,
                    top: 0,
                    left: '95%',
                    transform: 'translate(-95%, -11%)',
                  }}
                  variant="caption"
                >
                  {getPercentageDisplay(data)}
                </Typography>
              </div>
            )}
          </Box>
        )}
        <Handle type="source" position={Position.Right} id="right" style={{ background: '#fff' }} />
      </ReactFlowProvider>
    </div>
  );
});

CustomNode.propTypes = {
  data: PropTypes.object.isRequired,
};
CustomNode.displayName = 'CustomNode';

export default CustomNode;
