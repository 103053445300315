import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';

import { useMobile } from '../lib/StyleUtil';

const ProgressBar = ({ colorPrimary, colorBackground, value }) => {
  const isMobile = useMobile();

  const getHeight = () => {
    return isMobile ? '6px' : '.52vw';
  };

  const getBorderRadius = () => {
    return isMobile ? '3px' : '.52vw';
  };

  // from https://mui.com/material-ui/react-progress/#customization
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: getHeight(),
    borderRadius: getBorderRadius(),
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: colorBackground,
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: getBorderRadius(),
      backgroundColor: colorPrimary,
    },
  }));

  return <BorderLinearProgress variant="determinate" value={value} />;
};

ProgressBar.propTypes = {
  colorPrimary: PropTypes.string,
  colorBackground: PropTypes.string,
  value: PropTypes.number,
};

export default ProgressBar;
