import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
import React from 'react';

const LoadingSpinner = ({ size = 50 }) => {
  return (
    <Box sx={{ pr: `${size}px`, pb: `${size}px` }}>
      <Box sx={{ position: 'relative' }}>
        <CircularProgress
          variant="indeterminate"
          disableShrink
          sx={{
            color: '#BFBFBF',
            animationDuration: '700ms',
            position: 'absolute',
            left: 0,
          }}
          size={size}
          thickness={1}
        />
      </Box>
    </Box>
  );
};

LoadingSpinner.propTypes = {
  size: PropTypes.number,
};

export default LoadingSpinner;
