import '../ScaleOs/styles/ScaleOsStyles.scss';

import { Box, Divider, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';

import {
  getAlarmsQuery,
  getPortfolioPowerProductionQuery,
  getSiteMetadataQuery,
  getSiteTimeSeriesQuery,
  getUserProfileQuery,
} from '../../lib/Queries';
import ChartGraph from '../ScaleOs/Graphs/Chart/ChartGraph';
import SiteDiagram from '../ScaleOs/Graphs/Diagram/SiteDiagram';
import SiteAlarms from '../SiteAlarms/SiteAlarms';
import SiteHeader from '../SiteHeader/SiteHeader';
import TitleSelect from '../TitleSelect/TitleSelect';
import Topbar from '../Topbar/Topbar';
import GenerationTable from './../GenerationTable/GenerationTable';
import GraphUtil from './../ScaleOs/lib/GraphUtil';

const Portal = () => {
  const theme = useTheme();
  const [siteUuid, setSiteUuid] = useState(null);
  const [displayRange, setDisplayRange] = useState(null);
  const { data: userProfile } = useQuery({
    ...getUserProfileQuery(),
  });
  const { data: sitePortfolio } = useQuery({
    ...getPortfolioPowerProductionQuery(),
  });
  const { data: siteMetadata } = useQuery({
    ...getSiteMetadataQuery(siteUuid),
    enabled: !!siteUuid,
  });
  const { data: siteAlarms, isLoading: siteAlarmsIsLoading } = useQuery({
    ...getAlarmsQuery(siteUuid),
    enabled: !!siteUuid,
  });
  const { isLoading: timeSeriesIsLoading, data: timeSeries } = useQuery({
    ...getSiteTimeSeriesQuery(
      siteUuid,
      displayRange?.start,
      displayRange?.stop,
      displayRange?.interval,
    ),
    enabled: !!siteUuid && !!displayRange && !!displayRange.start && !!displayRange.stop,
  });

  useEffect(() => {
    if (!sitePortfolio) return;
    setSiteUuid(sitePortfolio.sites[0].uuid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sitePortfolio]);

  const getSiteSelectOptions = () => {
    if (!sitePortfolio) return [];
    return sitePortfolio.sites.map((site) => ({
      value: site.uuid,
      name: site.name,
    }));
  };

  useEffect(() => {
    if (!siteMetadata) return;
    const initialRange = GraphUtil.getInitialGraphRange(siteMetadata.timezone);
    setDisplayRange(initialRange);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteMetadata]);

  const styles = {
    logoutButton: { margin: '1rem' },
    mainRowsContainer: {
      width: '100%',
      minHeight: '100vh',
      margin: 0,
      justifyContent: 'center',
      alignItems: 'start',
      boxSizing: 'border-box',
      display: 'flex',
      backgroundColor: '#F8FAFB',
      px: '2rem',
    },
    contentContainer: {
      maxWidth: '1166px',
      width: '100%',
      margin: 0,
      gap: '13px',
      py: '56px',
      flexDirection: 'column',
      justifyContent: 'start',
      alignItems: 'stretch',
      boxSizing: 'border-box',
      display: 'flex',
    },
    card: {
      ...theme.classes.box,
      width: '100%',
      background: 'white',
      color: theme.palette.secondary.main,
      padding: '25px',
      boxSizing: 'border-box',
    },
    selectContainer: {
      justifyContent: 'start',
    },
  };

  return (
    <>
      <Topbar userName={userProfile?.name} />
      <Box spacing={3} sx={styles.mainRowsContainer} id="outer-grid">
        <Box sx={styles.contentContainer}>
          <Box sx={styles.selectContainer}>
            <TitleSelect
              options={getSiteSelectOptions()}
              selectedValue={siteUuid}
              setOption={setSiteUuid}
            />
          </Box>
          <Divider sx={{ my: '2px' }} />
          <Box mb="1rem">
            <SiteHeader metadata={siteMetadata} />
          </Box>
          <Box sx={[styles.card, { pb: '0px' }]}>
            {siteMetadata && (
              <Box className="scale-os-styles">
                <SiteDiagram site={siteMetadata} />
              </Box>
            )}
          </Box>
          <Box sx={[styles.card, { pr: '0px' }]}>
            {/* <Typography variant="subtitle2">Site Power</Typography> */}
            <Box className="scale-os-styles">
              {siteMetadata && displayRange && (
                <ChartGraph
                  site={siteMetadata}
                  displayRange={displayRange}
                  setDisplayRange={setDisplayRange}
                />
              )}
            </Box>
          </Box>
          <Box sx={{ display: 'flex', gap: '13px' }}>
            <Box sx={[styles.card, { flex: '2', pb: '3rem' }]}>
              <Typography variant="subtitle2" sx={{ mb: '27px' }}>
                Events
              </Typography>
              <SiteAlarms
                alarms={siteAlarms}
                timezone={siteMetadata?.timezone}
                loading={siteAlarmsIsLoading}
              />
            </Box>
            <Box sx={[styles.card, { flex: '1' }]}>
              <GenerationTable
                displayRange={displayRange}
                siteUuid={siteUuid}
                timeSeries={timeSeries}
                loading={timeSeriesIsLoading}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Portal;
