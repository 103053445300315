import './../../styles/Calendar.scss';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {
  Box,
  Divider,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Popover,
  TextField,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { Calendar } from 'react-multi-date-picker';

import Util from './../../lib/Util';
const DateOptionPicker = ({ site, range, updateDisplayRange }) => {
  const dropDownRef = useRef();

  const [selectedRange, setSelectedRange] = useState(range);
  const [selectedStart, setSelectedStart] = useState(null);
  const [selectedStop, setSelectedStop] = useState(null);
  const [open, setOpen] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const currentDay = new Date();
  const nextDay = new Date();
  const timezone = site.timezone;
  nextDay.setDate(currentDay.getDate() + 1);

  const handleChange = (range, selectedStart, selectedStop) => {
    // if the range selected is "custom" and the start / stop have not been set
    // then the calendar should be displayed.
    if (range === 'custom' && (!selectedStart || !selectedStop)) {
      return;
    }

    setSelectedRange(range);

    let newRange = range;
    let start = new Date();
    let stop = new Date();
    start.setHours(0, 0, 0, 0);
    stop.setHours(0, 0, 0, 0);
    if (range !== 'custom') {
      switch (newRange) {
        case 'today':
          stop.setDate(stop.getDate() + 1);
          break;
        case 'yesterday':
          start.setDate(start.getDate() - 1);
          break;
        case '7days':
          stop.setDate(stop.getDate() + 1);
          start.setDate(start.getDate() - 6);
          break;
        case '28days':
          stop.setDate(stop.getDate() + 1);
          start.setDate(start.getDate() - 27);
          break;
        case '90days':
          stop.setDate(stop.getDate() + 1);
          start.setDate(start.getDate() - 89);
          break;
        default:
          break;
      }
    } else {
      start = selectedStart.toDate();
      stop = selectedStop.toDate();
      // add one to the stop date, the dates should be inclusive of the selected range.
      stop.setDate(stop.getDate() + 1);
      start.setHours(0, 0, 0, 0);
      stop.setHours(0, 0, 0, 0);
    }

    let rangeObj = {
      range: newRange,
      start: Util.toISOStringWithTimezone(start, timezone),
      stop: Util.toISOStringWithTimezone(stop, timezone),
    };
    updateDisplayRange(rangeObj);
    setSelectedStart(start);
    setSelectedStop(stop);
    setOpen(false);
  };

  const openClose = () => {
    setOpen(!open);
  };

  const handleCustomChange = (values) => {
    if (values?.length === 2) {
      let start = values[0];
      let stop = values[1];
      openClose();
      setShowCalendar(false);
      handleChange('custom', start, stop);
    }
  };

  const getRangeLabel = () => {
    // stop is at midnight the following day, so move date back one day to display range correctly.
    let displayStop = getDisplayStop();
    return (
      selectedStart.toLocaleDateString('en-US') + ' - ' + displayStop.toLocaleDateString('en-US')
    );
  };

  const getDisplayStop = () => {
    let displayStop = new Date(selectedStop);
    displayStop.setDate(displayStop.getDate() - 1);
    return displayStop;
  };

  const getMenuItem = (value, children) => (
    <MenuItem value={value} className="date-range-menu" sx={styles.input}>
      {children}
    </MenuItem>
  );

  const styles = {
    input: {
      fontFamily: 'Inter, Arial, Helvetica, sans-serif',
      fontWeight: 300,
    },
  };

  return (
    <Box width="515px">
      <FormControl
        fullWidth
        size="small"
        sx={{
          '& *': {
            // Target all child elements of FormControl
            fontWeight: '300 !important', // Set font weight for all elements
          },
        }}
      >
        <InputLabel id="trend-date-range-label"></InputLabel>
        <TextField
          id="trend-date-select"
          data-testid="date-option-picker"
          ref={dropDownRef}
          select
          value={selectedRange}
          onChange={(ev) => {
            handleChange(ev.target.value);
          }}
          sx={styles.input}
          SelectProps={{
            IconComponent: (props) => null,
            onClose: openClose,
            onOpen: openClose,
            open: open,
            sx: [
              {
                '& .MuiSelect-select': { padding: '8px !important' },
              },
              styles.input,
            ],
            MenuProps: {
              PaperProps: {
                sx: {
                  background: 'white',
                  border: '1px solid rgb(225, 226, 228)',
                  '& .MuiMenuItem-root': {
                    background: 'white',
                  },
                },
              },
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" sx={{ cursor: 'pointer' }}>
                {open ? <ExpandLess onClick={openClose} /> : <ExpandMore onClick={openClose} />}
              </InputAdornment>
            ),
          }}
        >
          {getMenuItem('today', 'Today')}
          {getMenuItem('yesterday', 'Yesterday')}
          {getMenuItem('7days', 'Last 7 Days')}
          {getMenuItem('28days', 'Last 28 Days')}
          {getMenuItem('90days', 'Last 90 Days')}
          <Divider />
          <MenuItem
            value="custom"
            className="date-range-menu"
            onClick={(ev) => {
              setShowCalendar(true);
            }}
            sx={styles.input}
          >
            {selectedRange === 'custom' && selectedStart && selectedStop && !open
              ? getRangeLabel()
              : 'Custom'}
          </MenuItem>
        </TextField>
      </FormControl>
      <Popover
        open={showCalendar}
        anchorEl={dropDownRef.current}
        onClose={() => setShowCalendar(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Calendar
          className="scale"
          range
          rangeHover
          onChange={handleCustomChange}
          maxDate={new Date()}
          value={[selectedStart ? selectedStart : null, selectedStop ? getDisplayStop() : null]}
        />
      </Popover>
    </Box>
  );
};

DateOptionPicker.propTypes = {
  site: PropTypes.object.isRequired,
  updateDisplayRange: PropTypes.func,
  range: PropTypes.string,
};

export default DateOptionPicker;
