import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { CSVLink } from 'react-csv';

import { getCSVData } from '../lib/DataUtil';
import useStore from '../state/KioskStore';

const CSVDownload = () => {
  const theme = useTheme();
  const store = useStore((state) => state);

  const styles = {
    csvDownload: {
      ...theme.classes.box,
      backgroundColor: 'RGBA(255, 255, 255, 0.8)',
      width: { xs: '35px', xl: '50px' },
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    csvIcon: { width: { xs: '14px', xl: '20px' }, position: 'relative', top: '2px', right: '-1px' },
  };

  const getCSVName = () => {
    const siteName = store.siteMetadata.site_name;
    const timeRange = store.timeRangeKey;
    const siteNameFormatted = siteName.replace(/ /g, '_');
    return `${siteNameFormatted}_${timeRange}.csv`;
  };

  return (
    <CSVLink
      data={getCSVData(store.timeSeriesData)}
      filename={getCSVName()}
      target="_blank"
      rel="noreferrer"
      style={{ display: 'flex' }}
      data-testid="csv-download"
    >
      <Box sx={styles.csvDownload}>
        <Box sx={styles.csvIcon}>
          <img src={'/icons/download-icon.svg'} alt={'download icon'} style={{ width: '100%' }} />
        </Box>
      </Box>
    </CSVLink>
  );
};

export default CSVDownload;
